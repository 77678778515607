import React from "react";
import HeaderLocation from "./HeaderLocation";
function HeaderPwa() {
  return (
    <div id="headerPwaMain">
      <HeaderLocation></HeaderLocation>
    </div>
  )



}

export default HeaderPwa;
