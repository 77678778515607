import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Button } from "react-bootstrap";
import MembreStructurel from "./MembreStructurel";
import {
  onSnapshot,
  query,
  doc,
} from "firebase/firestore";
function ManagementBureau() {
  const { db, onShowModalSelection, getLicencie, getLicencies } = useGlobalContext();
  const [membresBureau, setMembresBureau] = useState([]);
  const [president, setPresident] = useState(null);
  const [vicePresident, setVicePresident] = useState(null);
  const [tresorier, setTresorier] = useState(null);
  const [secretaire, setSecretaire] = useState(null);

  useEffect(() => {
    const q = query(doc(db.db, "clubs/badlevier"));
    const unsubscribeBureau = onSnapshot(q, async (doc) => {
      const badlevier = doc.data();
      const membres = [];
      for (const membreUid of badlevier["bureau"]["membres"]) {
        membres.push(await getLicencie(membreUid));
      }
      setMembresBureau(membres.filter(lic => lic != null));
      setPresident(await getLicencie(badlevier["bureau"]["président"]));
      setVicePresident(await getLicencie(badlevier["bureau"]["vice-président"]));
      setTresorier(await getLicencie(badlevier["bureau"]["trésorier"]));
      setSecretaire(await getLicencie(badlevier["bureau"]["secrétaire"]));
    });

    return () => {
      unsubscribeBureau();
    };
  }, [db, getLicencie]);

  const selectionMembre = async () => {
    const licencies = await getLicencies();
    let membreAlreadyInBureau = membresBureau.map((user) => user.uid);
    if (president != null)
      membreAlreadyInBureau = membreAlreadyInBureau.concat(president.uid);
    if (vicePresident != null)
      membreAlreadyInBureau = membreAlreadyInBureau.concat(vicePresident.uid);
    if (tresorier != null)
      membreAlreadyInBureau = membreAlreadyInBureau.concat(tresorier.uid);
    if (secretaire != null)
      membreAlreadyInBureau = membreAlreadyInBureau.concat(secretaire.uid);
    onShowModalSelection(
      ajoutMembre,
      "Ajouter un membre",
      membreAlreadyInBureau,
      () => licencies
    );
  };

  const ajoutMembre = async (selectedUsers) => {
    if (selectedUsers.length !== 1)
      console.error("Plusieurs users sélectionné");
    const uid = selectedUsers[0];
    await db.addMembreBureau(uid);
  };

  const onRemoveMembreBureau = async (user) => {
    await db.removeMembreBureau(user.uid);
  };

  const changePresident = async (selectedUsers) => {
    if (selectedUsers.length !== 1)
      console.error("Plusieurs users sélectionné");
    const uid = selectedUsers[0];
    await db.setPresident(uid);
  };
  const changeVicePresident = async (selectedUsers) => {
    if (selectedUsers.length !== 1)
      console.error("Plusieurs users sélectionné");
    const uid = selectedUsers[0];
    await db.setVicePresident(uid);
  };
  const changeTresorier = async (selectedUsers) => {
    if (selectedUsers.length !== 1)
      console.error("Plusieurs users sélectionné");
    const uid = selectedUsers[0];
    await db.setTresorier(uid);
  };
  const changeSecretaire = async (selectedUsers) => {
    if (selectedUsers.length !== 1)
      console.error("Plusieurs users sélectionné");
    const uid = selectedUsers[0];
    await db.setSecretaire(uid);
  };

  return (
    <Container>
      <h3 className="section">Membres structurels</h3>
      <Container>
        <div className="helpItem">
          <i className="bi bi-question-circle"></i>
          <div style={{ marginLeft: "20px" }}>
            <span>Seul un administrateur peut nommer le président</span>
            <br />
            <span>Seul le président peut nommer les membres du bureau</span>
          </div>
        </div>
        <div
          className="card-custom1"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "500px",
          }}
        >
          <MembreStructurel
            onShowModalSelection={async () => {
              const licencies = await getLicencies();
              onShowModalSelection(
                changePresident,
                "Changer le président",
                president ? [president.uid] : [],
                () => licencies
              )
            }

            }
            header="Le président"
            currentMembre={president}
            rights={db.isAdmin}
          ></MembreStructurel>
          <MembreStructurel
            onShowModalSelection={async () => {
              const licencies = await getLicencies();
              onShowModalSelection(
                changeVicePresident,
                "Changer le vice président",
                vicePresident ? [vicePresident.uid] : [],
                () => licencies
              )
            }

            }
            header="Le vice président"
            currentMembre={vicePresident}
            rights={db.isPresident || db.isAdmin}
          ></MembreStructurel>
          <MembreStructurel
            onShowModalSelection={async () => {
              const licencies = await getLicencies();
              onShowModalSelection(
                changeTresorier,
                "Changer le trésorier",
                tresorier ? [tresorier.uid] : [],
                () => licencies
              )
            }

            }
            header="Le trésorier"
            currentMembre={tresorier}
            rights={db.isPresident || db.isAdmin}
          ></MembreStructurel>
          <MembreStructurel
            onShowModalSelection={async () => {
              const licencies = await getLicencies();
              onShowModalSelection(
                changeSecretaire,
                "Changer le secrétaire",
                secretaire ? [secretaire.uid] : [],
                () => licencies
              )
            }

            }
            header="Le secrétaire"
            currentMembre={secretaire}
            rights={db.isPresident || db.isAdmin}
          ></MembreStructurel>
        </div>
      </Container>

      <h3
        className="section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Autres membres</span>
        <Button
          className="btn btn-custom2"
          onClick={selectionMembre}
          disabled={!db.isPresident && !db.isAdmin}
        >
          Ajouter un membre
        </Button>
      </h3>
      <Container>
        <div className="helpItem">
          <i className="bi bi-question-circle"></i>
          <div style={{ marginLeft: "20px" }}>
            <span>
              Seul le président peut ajouter ou retirer des membres dans le
              bureau
            </span>
          </div>
        </div>
        <div
          className="card-custom1"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "500px",
          }}
        >
          {membresBureau.length === 0 ? (
            <span>Aucun membres</span>
          ) : (
            membresBureau.map((user, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                    backgroundColor: "white",
                    marginBottom: "10px",
                    borderRadius: "3px",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      textAlign: "left",
                      flex: "1 1 auto",
                    }}
                  >
                    {user.displayName}
                  </span>

                  <Button
                    className="btn btn-custom5"
                    onClick={() => onRemoveMembreBureau(user)}
                    disabled={!db.isPresident && !db.isAdmin}
                  >
                    Retirer
                  </Button>
                </div>
              );
            })
          )}
        </div>
      </Container>
    </Container >
  );
}

export default ManagementBureau;
