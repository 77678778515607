import React, { createContext, useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import ModalSelection from "./ModalSelection";
import OffCanvasBottom from "./OffCanvasBottom.js";
import { usePreGlobalContext } from "./PreGlobalContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import UserPresentation from "./UserPresentation.js";
import MiniUserPresentation from "./MiniUserPresentation.js";
import MiniUserPresentation2 from "./MiniUserPresentation2.js";
import ModalImageCustom from "./ModalImageCustom.js";


//import Cookies from "js-cookie";
//import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";

// Créez un contexte
const GlobalContext = createContext({});

// Créez un composant Provider pour envelopper votre application
function GlobalProvider({ children, firebase }) {
  const { db, online, dbAvailable, auth, user, setUser } = usePreGlobalContext();
  const isPwa = true; //window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true;
  //const [loadingGlobal, setLoadingGlobal] = useState(true);
  let licencies = []; //mise en cache des licenciés
  let allLicencies = []; //mise en cache des licenciés
  let licenciesFetched = false;
  let allLicenciesFetched = false;

  const PageUrl = {
    HOME: "/",
    SIGNIN: "/signIn",
    SIGNUP: "/signUp",
    CONTACT: "/contact",
    PRATICAL_INFORMATION: "/praticalInfomation",
    INSCRIPTION: "/inscription",
    TRAINING: "/training",
    INTERCLUB: "/interclub",
    RESET_PASSWORD: "/resetPassword",
    EDIT_PROFIL: "/editProfil",
    SCHEDULE: "/praticalInfomation#schedule",
    EVENTS: "/events",
    DOCUMENTS: "/documents",
    SHOP: "/shop",
    MANAGEMENT_LICENCES: "/managementLicences",
    MANAGEMENT_BUREAU: "/managementBureau",
    MANAGEMENT_CRENEAUX: "/managementCreneaux",
    MANAGEMENT_EQUIPES: "/managementEquipes",
    MANAGEMENT_EVENTS: "/managementEvents",
    MANAGEMENT_DOCUMENTS: "/managementDocuments",
    MANAGEMENT_TRESORERIE: "/managementTresorerie",
    MANAGEMENT_BOUTIQUE: "/managementBoutique",
    CLUB: "/club",
    PARTNERS: "/partners",
    GENERATOR: "/generator",
    GENERATOR_TOURNOI: "/generator/tournoi",
    GENERATOR_JOUEURS: "/generator/joueurs",
    GENERATOR_PARAMETRES: "/generator/parametres",
    GENERATOR_HELP: "/generator/help",
    GENERATOR_CREDIT: "/generator/credit",
  };
  const EventType = {
    ADULTE_TRAINING: "Adulte",
    YOUNG_TRAINING: "Jeune",
    INTERCLUB: "Interclub",
  };
  const AccessType = {
    BUREAU: "Bureau",
    CLUB: "Club",
    PUBLIC: "Public",
  };
  const LevelType = {
    D1: "D1",
    D2: "D2",
    D3: "D3",
  };
  const TypeReglement = {
    VIREMENT_BANCAIRE: "Virement bancaire",
    ESPECE: "Espèces",
    CHEQUE: "Chèque",
    PORTE_FEUILLE: "Portefeuille",
  };
  const StatusMouvement = {
    ATTENTE_PAIEMENT: "Attente paiement",
    ATTENTE_DEPOT: "Attente dépôt",
    ATTENTE_VALIDATION: "Attente validation",
    ATTENTE_DISTRIBUTION: "Attente distribution",
    CLOTURE: "Clôturé",
  };
  const TypeMouvement = {
    DEPOT: "Dépôt",
    ACHAT: "Achat",
  };
  const TypeMotifStock = {
    INTERCLUB: "Interclub",
    ERREUR: "Erreur de stock",
    ACHAT: "Achat",
    RETOUR_COMMANDE: "Retour commande",
    COMMANDE: "Commande",
    AUTRE: "Autre motif",
    VERIFICATION: "Vérification"
  };
  const DaysOfWeek = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const Locations = {
    SALLE_DES_SPORT_LEVIER: "Salle des sports de Levier",
    GYMNASE_FAUVETTES: "Gymnase des Fauvettes",
  };


  const getLicencie = async (value, key = "uid") => {
    const index = licencies.findIndex(lic => lic[key] === value);
    if (index !== -1) {
      return licencies[index];
    } else {
      //mise en cache des licenciés au fur et à mesure
      const licencieOnDb = await db.getLicencie(value, key);
      if (licencieOnDb) {
        const licenciesVrac = [...licencies, licencieOnDb];
        licencies = (licenciesVrac.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        ));
        return licencieOnDb;
      }
    }
    return null;
  }

  const getLicencies = async (reset = false) => {
    if (reset || !licenciesFetched) {
      const licenciesDb = await db.getLicencies();
      const sortedLicencies = licenciesDb.sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
      );
      licencies = sortedLicencies;
      licenciesFetched = true;
      return sortedLicencies;
    }
    return licencies;
  }


  const getAllLicencies = async (reset = false) => {
    if (reset || !allLicenciesFetched) {
      const licenciesDb = await db.getAllLicencies();
      const sortedLicencies = licenciesDb.sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
      );
      allLicencies = sortedLicencies;
      allLicenciesFetched = true;
      return sortedLicencies;
    }
    return allLicencies;
  }

  const [showAlert, setShowAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState("");
  const [messageAlert, setMessageAlert] = useState("");
  const [stateAlert, setStateAlert] = useState("");
  const handleShowAlert = (title, message, state, delay = 5000) => {
    setTitleAlert(title);
    setMessageAlert(message);
    setStateAlert(state);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, delay);
  };
  const handleCloseAlert = () => setShowAlert(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [button1Confirmation, setButton1Confirmation] = useState("");
  const [button2Confirmation, setButton2Confirmation] = useState("");
  const [messageConfirmation, setMessageConfirmation] = useState("");
  const [titleConfirmation, setTitleConfirmation] = useState("");
  const [stateConfirmation, setStateConfirmation] = useState("");
  const [onValidConfirmation, setOnValidConfirmation] = useState(null);
  const [onValidPromiseConfirmation, setOnValidPromiseConfirmation] =
    useState(null);
  const handleShowConfirmation = (
    title,
    message,
    state,
    button1,
    button2,
    onValid,
    onValidPromise
  ) => {
    setTitleConfirmation(title);
    setMessageConfirmation(message);
    setStateConfirmation(state);
    setButton1Confirmation(button1);
    setButton2Confirmation(button2);
    setOnValidConfirmation(() => onValid);
    setOnValidPromiseConfirmation(() => onValidPromise);
    setShowConfirmation(true);
  };
  const handleCloseConfirmation = () => setShowConfirmation(false);

  const [modalSelectionShow, setModalSelectionShow] = useState(false);
  const [getItems, setGetItems] = useState(null);
  const [onValidSelection, setOnValidSelection] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [headerSelection, setHeaderSelection] = useState("");
  const onShowModalSelection = (
    onValidSelection,
    headerSelection,
    selectedItems,
    getItems
  ) => {
    setOnValidSelection(() => onValidSelection);
    setHeaderSelection(headerSelection);
    setSelectedItems(selectedItems);
    setGetItems(() => getItems);
    setModalSelectionShow(true);
  };
  const onHideModalSelection = () => {
    setModalSelectionShow(false);
  };

  const handleSendMailVerif = async (email) => {
    await auth
      .sendEmailVerif(email)
      .then(() => {
        const message = "Email de vérification envoyé";
        handleShowAlert(
          message,
          "Vous pouvez cliquer sur le lien dans le mail pour valider votre compte",
          "warning"
        );
        return true;
      })
      .catch(() => {
        handleShowAlert("Erreur", "Problème dans l'envoi du mail", "success");
        return false;
      });
  };


  const getLastLoginAt = (user) => {
    if (user && user.lastLoginAt) {
      const date = user.lastLoginAt.toDate();
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const dateStr = date.toLocaleDateString("fr-FR", options);
      return dateStr;
    } else {
      return null;
    }
  };

  const getLastModifiedSynchro = (user) => {
    if (user && user.lastModifiedSynchro) {
      const date = user.lastModifiedSynchro.toDate();
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const dateStr = date.toLocaleDateString("fr-FR", options);
      return dateStr;
    } else {
      return null;
    }
  };


  const getDateStrFromTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const annee = date.getFullYear();
    const mois = (date.getMonth() + 1).toString().padStart(2, '0');  // Les mois vont de 0 à 11, d'où l'ajout de 1
    const jour = date.getDate().toString().padStart(2, '0');
    return `${annee}-${mois}-${jour}`;
  }

  const getDateShort = (timestamp) => {
    const dateObject = timestamp.toDate();
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const dateFormatee = dateObject.toLocaleDateString('fr-FR', options);
    return dateFormatee;
  };

  const getDateTraining = (timestamp) => {
    const dateObject = timestamp.toDate();
    const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };
    const dateFormatee = dateObject.toLocaleDateString('fr-FR', options);
    return dateFormatee;
  };

  const getDateTournoi = (timestampDate) => {
    const dateObject = new Date(timestampDate);
    const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };
    const dateFormatee = dateObject.toLocaleDateString('fr-FR', options);
    return dateFormatee;
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getDateTrainingWithDay = (timestamp) => {
    return capitalize(format(timestamp.toDate(), "EEEE d MMM yyyy", {
      locale: frLocale,
    }));
  };

  const getPhoto = (currentUser, style, badge) => {
    const photoExist = currentUser && (currentUser.photoURLCustom || currentUser.photoURL);
    if (!photoExist) {
      style.borderRadius = "";
      style.border = "none";
    }
    return photoExist ? (
      <div style={{
        position: "relative",
      }}>
        <img
          alt="profil"
          style={style}
          src={
            currentUser.photoURLCustom
              ? currentUser.photoURLCustom
              : currentUser.photoURL
          }
          title={currentUser.displayName}
        />
        {badge && badge}
      </div>

    ) : (
      <FontAwesomeIcon style={style} icon={faUser} >
        {badge && badge}
      </FontAwesomeIcon >
    );
  };

  const getPhotoArticle = (photoURL, title, style = {}) => {
    return photoURL ? (
      <ModalImageCustom
        className="photoArticle"
        small={photoURL}
        large={photoURL}
        alt={title ? title : null}
        style={style}
      />
    ) : (
      <span>Aucune image</span>
    );
  };

  const getPhotoEvent = (photoURL, title, style = {}, className) => {
    return photoURL && photoURL !== "" ? (
      <ModalImageCustom
        small={photoURL}
        large={photoURL}
        alt={title}
        style={style}
        className={className}
      />
    ) : (
      null
    );
  };

  const getPhotoArticle2 = (photoURL, title, style = {}) => {
    return photoURL ? (
      <img
        alt={title}
        style={style}
        src={
          photoURL
        }
      />
    ) : (
      <FontAwesomeIcon style={style} icon={faUser} />
    );
  };

  const resizeImage = (image, maxHeight) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const newWidth = maxHeight * aspectRatio;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = newWidth;
        canvas.height = maxHeight;
        ctx.drawImage(img, 0, 0, newWidth, maxHeight);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.7); // Ajustez la qualité selon vos besoins
      };
      img.src = URL.createObjectURL(image);
    });
  };

  const getUserPresentation = (user, header, index) => {
    if (user) {
      return (
        <UserPresentation
          header={header}
          user={user}
          key={index}
        ></UserPresentation>
      );
    } else {
      return <></>;
    }
  };

  const getMiniUserPresentation = (user, header, index) => {
    if (user) {
      return (
        <MiniUserPresentation
          header={header}
          user={user}
          key={index}
        ></MiniUserPresentation>
      );
    } else {
      return <></>;
    }
  };

  const getMiniUserPresentation2 = (user, header, index) => {
    if (user) {
      return (
        <MiniUserPresentation2
          header={header}
          user={user}
          key={index}
        ></MiniUserPresentation2>
      );
    } else {
      return <></>;
    }
  };


  //useEffect(() => {
  /*let unsubscribeUser = null;
  let unsubscribeLicencie = null;
  const unsubscribe = onAuthStateChanged(auth.auth, async (userAuth) => {
    let currentUser = null;
    if (userAuth) {
      currentUser = await authUser(userAuth);
      if (userAuth.email) {
        if (userAuth.emailVerified) {
          console.log(
            'authStateChanged : user email : "' +
            userAuth.email +
            '" connecté'
          );
        } else {
          console.log(
            'authStateChanged : user "' +
            userAuth.email +
            '" non connecté car email non vérifié'
          );
        }
      } else if (userAuth.phoneNumber) {
        currentUser = await authUser(userAuth);
        console.log(
          'authStateChanged : user phoneNumber : "' +
          userAuth.phoneNumber +
          '" connecté'
        );
      }
    } else {
      console.log("authStateChanged : no user");
    }
    if (currentUser) {
      if (unsubscribeLicencie) unsubscribeLicencie();
      const q = query(collection(db.db, "clubs/badlevier/licenciés"), where("uid", "==", currentUser.uid), limit(1));
      unsubscribeLicencie = onSnapshot(q, async (querySnapshotLicencie) => {
        await Promise.all(querySnapshotLicencie.docs.map(async (doc) => {
          const current = doc.data();
          db.fetchUserRights(current, true);
          setUser(current);
        }));
      });
      if (unsubscribeUser) unsubscribeUser();
      const q1 = query(collection(db.db, "users"), where("uid", "==", currentUser.uid), limit(1));
      unsubscribeUser = onSnapshot(q1, async (querySnapshotUser) => {
        await Promise.all(querySnapshotUser.docs.map(async (doc) => {
          const current = doc.data();
          db.fetchUserRights(current, false);
          setUser(current);
        }));
      });
    }
    setUser(currentUser);
  });*/


  /*const credentialBadlevier = Cookies.get("credentialBadlevier");
  if (credentialBadlevier) {
    try {
      const cred = JSON.parse(credentialBadlevier);
      const credential = PhoneAuthProvider.credential(
        cred.verificationId,
        cred.code
      );
      signInWithCredential(auth.auth, credential)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === "auth/code-expired") {
            handleShowAlert("Code authentification expiré", "", "warning");
          } else {
            handleShowAlert(
              "Erreur pendant la connexion par téléphone",
              "",
              "warning"
            );
          }
          //Cookies.remove("credentialBadlevier");
          //console.log("cookie supprimé");
          console.error("pb signInWithCredential", errorMessage);
        });
    } catch (error) {}
  }*/

  /*return () => {
    unsubscribe()
  };*/

  //}, [auth, db, authUser]);

  /*if (loadingGlobal) {
     return (
       <div
         style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           flexDirection: "column",
           height: "100%",
           width: "100%",
           position: "fixed",
         }}
       >
         <img
           title="Badlevier"
           alt="Badlevier"
           src="./favicon.ico"
           style={{ width: "256px", height: "256px" }}
         ></img>
         <Spinner animation="border" role="status"></Spinner>
       </div>
     );
   }*/

  return (
    <GlobalContext.Provider
      value={{
        firebase,
        PageUrl,
        EventType,
        AccessType,
        LevelType,
        TypeReglement,
        StatusMouvement,
        TypeMouvement,
        TypeMotifStock,
        DaysOfWeek,
        Locations,
        handleShowAlert,
        handleShowConfirmation,
        handleCloseConfirmation,
        onShowModalSelection,
        handleSendMailVerif,
        db,
        auth,
        online,
        dbAvailable,
        user,
        setUser,
        licencies,
        getLicencie,
        getLicencies,
        getAllLicencies,
        resizeImage,
        getLastLoginAt,
        getLastModifiedSynchro,
        getPhoto,
        getPhotoArticle,
        getPhotoArticle2,
        getPhotoEvent,
        getDateShort,
        getDateTraining,
        getDateTrainingWithDay,
        getDateTournoi,
        getDateStrFromTimestamp,
        isPwa,
        getUserPresentation,
        getMiniUserPresentation,
        getMiniUserPresentation2
      }}
    >
      {children}
      <Alert
        show={showAlert}
        key={stateAlert}
        variant={stateAlert}
        onClose={handleCloseAlert}
        dismissible
      >
        <Alert.Heading>{titleAlert}</Alert.Heading>
        {messageAlert}
      </Alert>
      <OffCanvasBottom
        show={showConfirmation}
        onClose={handleCloseConfirmation}
        button1={button1Confirmation}
        button2={button2Confirmation}
        title={titleConfirmation}
        message={messageConfirmation}
        state={stateConfirmation}
        onValid={onValidConfirmation}
        onValidPromise={onValidPromiseConfirmation}
      />
      <ModalSelection
        show={modalSelectionShow}
        onHide={onHideModalSelection}
        onValidSelection={onValidSelection}
        onCancelSelection={onHideModalSelection}
        getItems={getItems}
        selectedItems={selectedItems}
        header={headerSelection}
        contentKeys={["displayName", "firstName", "lastName"]}
        uidKey="uid"
        selectionMultiple={false}
      />
    </GlobalContext.Provider>
  );
}

// Créez un hook pour accéder au contexte
function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalContext doit être utilisé dans un GlobalProvider"
    );
  }
  return context;
}

export { GlobalProvider, useGlobalContext };
