import React, { useState, useEffect } from 'react';
import { Container, Button, Placeholder } from 'react-bootstrap';
import { useGlobalContext } from "./GlobalContext";
import NumberSpinner from "./NumberSpinner.js";
import { useNavigate } from "react-router-dom";
import {
  onSnapshot,
  query,
  collection
} from "firebase/firestore";

function Shop() {

  const { db, PageUrl, user, getPhotoArticle, TypeMouvement, StatusMouvement, TypeReglement } = useGlobalContext();
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [panier, setPanier] = useState([]);
  const [loading, setLoading] = useState(true);

  if (user) {
    if (user.panier) setPanier(user.panier);
  }

  const validePanier = async () => {
    await db.addMouvement(
      {
        typeMouvement: TypeMouvement.ACHAT,
        motif: TypeMouvement.ACHAT,
        typeReglement: TypeReglement.PORTE_FEUILLE,
        montant: panier.reduce((accumulateur, t) => accumulateur + t["prix"] * t["nb"], 0),
        status: StatusMouvement.ATTENTE_PAIEMENT,
        remiseMembreBureau: null,
        licencie: user.uid,
        createur: user.uid,
        panier: panier
      });
    navigate(PageUrl.EDIT_PROFIL + "/commandes", { to: PageUrl.EDIT_PROFIL });
    //handleShowAlert("Votre commande est validée", "Vous pouvez suivre son avancement dans votre compte", "success");
  }

  const afterChangeValue = async (article, nb) => {
    const ind = panier.findIndex(a => a.uid === article.uid);
    if (ind !== -1) {
      if (nb === 0) {
        panier.splice(ind, 1);
      } else {
        panier[ind]["nb"] = nb;
      }
      setPanier([...panier]);
    } else {
      const newPanier = panier;
      article["nb"] = nb;
      newPanier.push(article);
      setPanier([...newPanier]);
    }
  }

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/articles"));
    const unsubscribeArticle = onSnapshot(q, async (querySnapshotArticle) => {
      const retour = [];
      await Promise.all(
        querySnapshotArticle.docs.map(async (doc) => {
          const article = doc.data();
          const articleFindIndex = retour.findIndex(cre => cre.uid === article.uid);
          if (articleFindIndex > -1) {
            retour[articleFindIndex] = article;
          } else {
            retour.push(article);
          }
        })
      );
      setArticles([...retour]);
      setLoading(false);
    });

    return () => {
      unsubscribeArticle();
    };

  }, [db]);

  const nbArticlesPanier = panier.reduce((accumulateur, t) => accumulateur + t["nb"], 0)

  return (
    <Container>
      {panier && panier.length > 0 &&
        <div
          className="section"
          style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >
            <span style={{ marginLeft: "5px" }}>{nbArticlesPanier} article{nbArticlesPanier > 1 && "s"}</span>
            <span>|</span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ marginRight: "10px", fontWeight: "bold" }}>Total</span>
              <span style={{ fontWeight: "bold" }}>{panier.reduce((accumulateur, t) => accumulateur + t["prix"] * t["nb"], 0)} €</span>
            </div>
            <Button className="btn btn-custom2"
              disabled={panier.length === 0}
              onClick={validePanier}>
              Valider le panier
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              backgroundColor: "white"
            }}
          >
            {panier.map((article, index) => {
              return (
                <div
                  key={index}
                  style={{
                    fontSize: "0.8em",
                    flex: "1 1 auto",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    padding: "5px 10px",
                    borderBottom: "1px solid gray"
                  }}
                >
                  <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                    x {article["nb"]}
                  </span>
                  <span style={{
                    textWrap: "nowrap",
                    textOverflow: "ellipsis",
                    position: "relative",
                    flex: "1 1 auto",
                    overflow: "hidden"
                  }}>
                    {article.title}
                  </span>
                  <span style={{ minWidth: "70px", textAlign: "right" }}>
                    {article.prix} €
                  </span>

                </div>

              );
            })
            }
          </div>
        </div>
      }
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ?
          <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
            <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
          </Placeholder>
          : articles.length === 0 ? (
            <span style={{ fontStyle: "italic" }}>
              Aucun article
            </span>
          ) : (
            articles.map((article, index) => {
              const ind = panier.findIndex(a => a.uid === article.uid);
              const nbArticleInPanier = ind !== -1 ? panier[ind]["nb"] : 0;
              const reste = article.qte - nbArticleInPanier;
              return (
                <div
                  key={index}
                  style={{
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    margin: "10px",
                    width: "100%",
                    alignItems: "center",
                    padding: "10px",
                    boxShadow: "0px 0px 10px 2px gray",
                    marginBottom: "10px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      width: "100%"
                    }}
                  >
                    <span>
                      <b>{article.title}</b>
                    </span>
                    <span style={{ minWidth: "70px", textAlign: "right" }}>
                      {article.prix} €
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {article.photoUrl &&
                      getPhotoArticle(article.photoUrl, article.title, {
                        maxHeight: "150px",
                        maxWidth: "150px",
                        height: "100%",
                        width: "100%",
                        borderRadius: "5px",
                      })
                    }
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                      <span style={{ margin: "0px 5px" }}>{article.description}</span>
                      {db.isLicencie &&
                        <NumberSpinner
                          minValue={0}
                          maxValue={article["qte"]}
                          firstValue={0}
                          afterChangeValue={(nb) => afterChangeValue(article, nb)}
                        ></NumberSpinner>
                      }
                    </div>
                    {db.isLicencie &&
                      <span style={{ marginTop: "5px", fontSize: "0.8em", width: "100%", textAlign: "right", marginRight: "5px", fontWeight: reste < 3 ? "bold" : "", color: reste < 3 ? "darkred" : "" }}>
                        {reste === 0 ? "Plus de stock" : "Il en reste " + reste}
                      </span>
                    }
                  </div>
                </div>
              );
            })
          )}
      </Container>
    </Container >
  );
}

export default Shop;
