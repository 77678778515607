import React, { useState, useEffect } from "react";
import OffCanvasLeft from "./OffCanvasLeft.js";
import OffCanvasLeftGenerator from "./Generator/OffCanvasLeftGenerator.js";
import OffCanvasRight from "./OffCanvasRight.js";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";
import { Link } from "react-router-dom";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faUserFriends, faCog } from "@fortawesome/free-solid-svg-icons";

function FooterPwa({ theme, toggleTheme, setOnGenerator }) {
  const { online, dbAvailable, PageUrl } = useGlobalContext();
  const location = useLocation();
  const [borderHome, setBorderHome] = useState("");
  const [borderGeneratorJoueurs, setBorderGeneratorJoueurs] = useState("");
  const [borderInterclub, setBorderInterclub] = useState("");
  const [borderOffcanvasLeft, setBorderOffcanvasLeft] = useState("");
  const [borderOffcanvasRight, setBorderOffcanvasRight] = useState("");
  const [showOffcanvasLeft, setShowOffcanvasLeft] = useState(false);
  const handleCloseOffcanvasLeft = () => setShowOffcanvasLeft(false);
  const handleShowOffcanvasLeft = () => setShowOffcanvasLeft(true);
  const [showOffcanvasRight, setShowOffcanvasRight] = useState(false);
  const handleCloseOffcanvasRight = () => setShowOffcanvasRight(false);
  const handleShowOffcanvasRight = () => setShowOffcanvasRight(true);

  useEffect(() => {
    setBorderOffcanvasLeft(showOffcanvasLeft ? "green 3px solid" : "")
    setBorderOffcanvasRight(showOffcanvasRight || location.pathname === PageUrl.SIGNIN ? "green 3px solid" : "")
    setBorderHome(!showOffcanvasLeft && !showOffcanvasRight && location.pathname === PageUrl.HOME ? "green 3px solid" : "")
    setBorderGeneratorJoueurs(!showOffcanvasLeft && !showOffcanvasRight && location.pathname === PageUrl.TRAINING ? "green 3px solid" : "")
    setBorderInterclub(!showOffcanvasLeft && !showOffcanvasRight && location.pathname === PageUrl.INTERCLUB ? "green 3px solid" : "")
  }, [location, PageUrl.HOME, PageUrl.SIGNIN, PageUrl.TRAINING, PageUrl.INTERCLUB, showOffcanvasLeft, showOffcanvasRight])

  const isGenerator = location.pathname.includes(PageUrl.GENERATOR);
  const onTournoi = location.pathname.includes(PageUrl.GENERATOR_TOURNOI);

  if (isGenerator) {
    return (
      <div
        id="footerPwa"
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            width: "100%",
            height: "80px",
            fontSize: "0.8em",
            borderTop: "rgb(200, 200, 200) solid 1px"
          }}
        >
          {dbAvailable &&
            <OffCanvasLeftGenerator
              isPwa={true}
              show={showOffcanvasLeft}
              handleShow={handleShowOffcanvasLeft}
              handleClose={handleCloseOffcanvasLeft}
              handleCloseRight={handleCloseOffcanvasRight}
              borderOffcanvasLeft={borderOffcanvasLeft}
              setOnGenerator={setOnGenerator}>
            </OffCanvasLeftGenerator>
          }

          {/*<Link to={PageUrl.GENERATOR_JOUEURS}
            onClick={() => {
              setShowOffcanvasLeft(false);
              setShowOffcanvasRight(false);
            }}
            className="buttonMenuPwa"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "43px",
              boxSizing: "content-box", borderBottom: borderGeneratorJoueurs
            }}>
            <FontAwesomeIcon icon={faUserFriends} style={{ height: "22px", margin: "0px", padding: "5px" }} />
            <span style={{ fontSize: "0.8em" }}>Joueurs</span>
          </Link>*/}

          {onTournoi ?
            <div className="buttonMenuPwa"
              onClick={() => {
                setShowOffcanvasLeft(false);
                setShowOffcanvasRight(false);
                //generator.lancerTournoi();
              }}
              style={{
                display: "flex", flexDirection: "column", justifyContent: "center", height: "64px", width: "64px", borderRadius: "50%",
                boxShadow: "0px 0px 10px 2px gray",
                marginTop: "-16px", zIndex: "5", backgroundColor: "white"
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="3em"
                viewBox="0 0 512 512"
              >
                <path
                  fill="rgb(28, 105, 28)"
                  d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
                />
              </svg>
            </div>
            :
            <Link to={PageUrl.GENERATOR} className="buttonMenuPwa"
              onClick={() => {
                setShowOffcanvasLeft(false);
                setShowOffcanvasRight(false);
              }}
              style={{
                display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "64px", width: "64px", borderRadius: "50%",
                boxShadow: "0px 0px 10px 2px gray",
                marginTop: "-16px", zIndex: "5", backgroundColor: "white", border: borderHome
              }}>
              <img
                title="Badlevier"
                alt="Badlevier"
                src="./favicon.ico"
                style={{ width: "48px", height: "48px" }}
              ></img>
            </Link>
          }


          {/*<Link to={PageUrl.GENERATOR_PARAMETRES} className="buttonMenuPwa"
            onClick={() => {
              setShowOffcanvasLeft(false);
              setShowOffcanvasRight(false);
            }}
            style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "36px", boxSizing: "content-box", borderBottom: borderInterclub }}>
            <FontAwesomeIcon icon={faCog} style={{ height: "22px", margin: "0px", padding: "5px" }} />
            <span style={{ fontSize: "0.8em" }}>Paramètres</span>
          </Link>*/}

          {dbAvailable && !online ? (
            <div className="offlineMask">
              <img
                title="Pas de connexion internet"
                alt="Pas de connexion internet"
                src="./pas-de-wifi.png"
                style={{ width: "32px", height: "32px" }}
              ></img>
            </div>
          ) : dbAvailable && (
            <OffCanvasRight
              isPwa={true}
              show={showOffcanvasRight}
              handleShow={handleShowOffcanvasRight}
              handleClose={handleCloseOffcanvasRight}
              handleCloseLeft={handleCloseOffcanvasLeft}
              borderOffcanvasRight={borderOffcanvasRight}
              theme={theme}
              toggleTheme={toggleTheme}
            ></OffCanvasRight>
          )}
        </div>
      </div >
    )
  } else {
    return (
      <div
        id="footerPwa"
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            width: "100%",
            height: "80px",
            fontSize: "0.8em",
            borderTop: "rgb(200, 200, 200) solid 1px"
          }}
        >
          {dbAvailable &&
            <OffCanvasLeft
              isPwa={true}
              show={showOffcanvasLeft}
              handleShow={handleShowOffcanvasLeft}
              handleClose={handleCloseOffcanvasLeft}
              handleCloseRight={handleCloseOffcanvasRight}
              borderOffcanvasLeft={borderOffcanvasLeft}
              setOnGenerator={setOnGenerator}>
            </OffCanvasLeft>
          }
          {dbAvailable &&
            <Link to={PageUrl.TRAINING}
              onClick={() => {
                setShowOffcanvasLeft(false);
                setShowOffcanvasRight(false);
              }}
              className="buttonMenuPwa"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "43px",
                boxSizing: "content-box", borderBottom: borderGeneratorJoueurs
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32px"
                width="24px"
                viewBox="0 0 512 512"
              >
                <path
                  fill="rgb(28, 105, 28)"
                  d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
                />
              </svg>
              <span style={{ fontSize: "0.8em" }}>Entraînements</span>
            </Link>
          }

          <Link to={PageUrl.HOME} className="buttonMenuPwa"
            onClick={() => {
              setShowOffcanvasLeft(false);
              setShowOffcanvasRight(false);
            }}
            style={{
              display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "64px", width: "64px", borderRadius: "50%",
              boxShadow: "0px 0px 10px 2px gray",
              marginTop: "-16px", zIndex: "5", backgroundColor: "white", border: borderHome
            }}>
            <img
              title="Badlevier"
              alt="Badlevier"
              src="./favicon.ico"
              style={{ width: "48px", height: "48px" }}
            ></img>
          </Link>
          {dbAvailable &&

            <Link to={PageUrl.INTERCLUB} className="buttonMenuPwa"
              onClick={() => {
                setShowOffcanvasLeft(false);
                setShowOffcanvasRight(false);
              }}
              style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "36px", boxSizing: "content-box", borderBottom: borderInterclub }}>
              <i
                className="bi bi-trophy"
                style={{ fontSize: "1.8em", height: "35px", marginTop: "-9px" }}
              ></i>
              <span style={{ fontSize: "0.8em" }}>Interclubs</span>
            </Link>
          }

          {dbAvailable && !online ? (
            <div className="offlineMask">
              <img
                title="Pas de connexion internet"
                alt="Pas de connexion internet"
                src="./pas-de-wifi.png"
                style={{ width: "32px", height: "32px" }}
              ></img>
            </div>
          ) : dbAvailable && (
            <OffCanvasRight
              isPwa={true}
              show={showOffcanvasRight}
              handleShow={handleShowOffcanvasRight}
              handleClose={handleCloseOffcanvasRight}
              handleCloseLeft={handleCloseOffcanvasLeft}
              borderOffcanvasRight={borderOffcanvasRight}
              theme={theme}
              toggleTheme={toggleTheme}
            ></OffCanvasRight>
          )}
        </div>
      </div >
    )
  }





}

export default FooterPwa;
