import React from "react";
import OffCanvasLeft from "./OffCanvasLeft";
import OffCanvasRight from "./OffCanvasRight";
import HeaderLocation from "./HeaderLocation";
import { useGlobalContext } from "./GlobalContext";
import { Link } from "react-router-dom";

function Header(props) {
  const { online, PageUrl, isPwa } = useGlobalContext();
  return (
    <div
      id={props.id}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {isPwa ? (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <OffCanvasLeft isPwa={isPwa}></OffCanvasLeft>
          <Link to={PageUrl.TRAINING}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2em"
              viewBox="0 0 512 512"
            >
              <path
                fill="rgb(28, 105, 28)"
                d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
              />
            </svg>
          </Link>
          <Link to={PageUrl.INTERCLUB}>
            <i
              className="bi bi-trophy"
              style={{ fontSize: "2em", height: "60px" }}
            ></i>
          </Link>
          {!online ? (
            <div className="offlineMask">
              <img
                title="Pas de connexion internet"
                alt="Pas de connexion internet"
                src="./pas-de-wifi.png"
                style={{ width: "24px", height: "24px" }}
              ></img>
              Hors connexion
            </div>
          ) : (
            <OffCanvasRight
              isPwa={isPwa}
              theme={props.theme}
              toggleTheme={props.toggleTheme}
            ></OffCanvasRight>
          )}
        </div>
      ) : (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <OffCanvasLeft isPwa={isPwa}>
            <img
              title="Badlevier"
              alt="Badlevier"
              src="./favicon.ico"
              style={{ width: "48px", height: "48px" }}
            ></img>
          </OffCanvasLeft>
          <HeaderLocation></HeaderLocation>
          {!online ? (
            <div className="offlineMask">
              <img
                title="Pas de connexion internet"
                alt="Pas de connexion internet"
                src="./pas-de-wifi.png"
                style={{ width: "24px", height: "24px" }}
              ></img>
              Hors connexion
            </div>
          ) : (
            <OffCanvasRight
              isPwa={isPwa}
              theme={props.theme}
              toggleTheme={props.toggleTheme}
            ></OffCanvasRight>
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
