import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Header";
import HeaderPwa from "./HeaderPwa";
import Main from "./Main";
import { useGlobalContext } from "./GlobalContext";
import FooterPwa from "./FooterPwa.js";

function App() {
  const [theme, setTheme] = useState("light");
  const { isPwa, setDbAvailable } = useGlobalContext();
  const toggleTheme = () => {
    const targetTheme = theme === "light" ? "dark" : "light";
    document.documentElement.className = "";
    document.documentElement.classList.add(`${targetTheme}-mode`);
    setTheme(targetTheme);
  };

  try {
    return (
      <Router>
        {isPwa ? (
          <div style={{ minHeight: '100vh' }}>
            <HeaderPwa />
            <Main style={{ marginTop: "30px", paddingBottom: "120px" }} />
            <FooterPwa theme={theme} toggleTheme={toggleTheme} />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Header id="headerMain" theme={theme} toggleTheme={toggleTheme} />
            <Main style={{ marginTop: "80px" }} />
          </div>
        )}
      </Router>
    );
  } catch {
    setDbAvailable(false);
  }
}

export default App;
