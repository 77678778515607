import React from "react";
import { Link } from "react-router-dom";
import ModalImageCustom from "./ModalImageCustom.js";

function Footer() {
  return (
    <div id="footerMain">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1 1 auto",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Suivez-nous sur </span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: "1 1 auto",
            }}
          >
            <Link
              style={{ display: "flex", alignItems: "center", fontSize: "1em" }}
              className="btn btn-custom2 facebook"
              to="https://www.facebook.com/profile.php?id=100064846594124"
            >
              <i style={{ marginRight: "5px" }} className="bi bi-facebook"></i>
              Facebook
            </Link>
            <Link
              style={{
                width: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1em",
              }}
              className="btn btn-custom2 whatsapp"
              to="https://www.whatsapp.com/?lang=fr_FR"
            >
              <i className="bi bi-whatsapp" style={{ marginRight: "5px" }}></i>
              WhatsApp
            </Link>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: "1 1 auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <span
              style={{
                textAlign: "right",
                marginRight: "10px",
                fontSize: "15px",
                lineHeight: "1.4em",
              }}
            >
              Rejoignez la communauté WhatsApp Badlevier{" "}
            </span>
          </div>
          <ModalImageCustom
            className="qrCodeBadlevier"
            small="./qrCodeBadlevier.svg"
            large="./qrCodeBadlevier.svg"
            alt="Communauté WhatsApp Badlevier"
            style={{ width: "80vmin", height: "80vmin" }}
          />
        </div>
      </div>

      <div className="creditJohn">
        <hr />
        <span>Application créé par </span>
        <Link to="https://www.linkedin.com/in/merandat-jonathan-955796106/">
          {" "}
          Jonathan Merandat
        </Link>
      </div>
    </div>
  );
}

export default Footer;
