import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Button, Accordion, Placeholder, FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalArticle from "./ModalArticle.js";
import {
  onSnapshot,
  query,
  collection,
} from "firebase/firestore";
function ManagementBoutique() {
  const {
    db,
    user,
    getPhotoArticle,
    handleShowConfirmation,
    handleCloseConfirmation,
    getDateTraining,
    TypeMouvement,
    getLicencie,
    TypeMotifStock
  } = useGlobalContext();
  const [articles, setArticles] = useState([]);
  const [currentArticleEdit, setCurrentArticleEdit] = useState(null);
  const [modalArticleShow, setModalArticleShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nbWeekBefore, setNbWeekBefore] = useState(52);

  const modifyArticle = (article) => {
    setCurrentArticleEdit(article);
    setModalArticleShow(true);
  };
  const ajoutArticle = () => {
    setCurrentArticleEdit(null);
    setModalArticleShow(true);
  };

  const onValid = () => {
    setModalArticleShow(false);
  };

  const onCancel = () => {
    setModalArticleShow(false);
  };

  const onValidPurgeMouvements = async () => {
    //TODO
    handleCloseConfirmation();
  }

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/articles"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let retour = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const article = doc.data();
          for (let i = 0; i < article["mouvementStock"].length; i++) {
            article["mouvementStock"][i]["modifiedBy"] = await getLicencie(article["mouvementStock"][i]["modifiedBy"]);
            article["mouvementStock"][i]["licencie"] = await getLicencie(article["mouvementStock"][i]["licencie"]);
          }
          retour.push(article);
        })
      );
      retour = retour.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
      )
      setArticles(retour);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [db, getLicencie]);

  return (
    <Container>
      <div
        className="section"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button className="btn btn-custom2" onClick={ajoutArticle}>
            Ajouter un article
          </Button>
          <span
            style={{
              fontStyle: "italic",
              fontSize: "0.8em",
              marginLeft: "10px",
            }}
          >
            <b>{articles.length} article(s)</b>
          </span>
        </div>

      </div>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ?
          <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
            <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
          </Placeholder>
          : articles.length !== 0 && (
            <>
              <Accordion
                defaultActiveKey="0"
                style={{ maxWidth: "600px", width: "100%" }}
              >
                {articles.map((article, index) => {
                  const mouvementStock = article.mouvementStock;
                  return (
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      style={{
                      }}
                    >
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                flex: "1 1 auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  flex: "1 1 auto",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-around",
                                }}
                              >
                                <span style={{ marginBottom: "5px" }}>
                                  <b>{article.title}</b>
                                </span>


                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  fontWeight: "bold"
                                }}
                              >
                                <span style={{ minWidth: "70px", textAlign: "right" }}>
                                  {article.prix} €
                                </span>
                              </div>

                            </div>

                            <div
                              className="btn btn-custom1"
                              onClick={(e) => {
                                modifyArticle(article);
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                              />
                            </div>

                          </div>
                          <div style={{ width: "100%" }}>
                            <label>
                              Stock
                              <span>{article.qte}</span>
                            </label>
                            {/*article.stockDiff === null ?
                              <label>
                                Il s'agit du stock initial à la création de l'article
                              </label>
                              : article.stockDiff === 0 ?
                                <div style={{ marginLeft: "20px" }}>
                                  <label>
                                    Vérifié par
                                    <span>{licencieModifStock.displayName}</span>
                                  </label>
                                  <label>
                                    Vérifié le
                                    <span>{getDateTraining(article.stockLastModified)}</span>
                                  </label>
                                </div>
                                : article.stockDiff > 0 ?
                                  <div style={{ marginLeft: "20px" }}>
                                    <label>
                                      Modifié par
                                      <span>{licencieModifStock.displayName}</span>
                                    </label>
                                    <label>
                                      Modifié le
                                      <span>{getDateTraining(article.stockLastModified)}</span>
                                    </label>
                                    <label>
                                      Différence
                                      <span>{article.stockDiff} de plus</span>
                                    </label>
                                  </div>
                                  :
                                  <div style={{ marginLeft: "20px" }}>
                                    <label>
                                      Modifié par
                                      <span>{licencieModifStock.displayName}</span>
                                    </label>
                                    <label>
                                      Modifié le
                                      <span>{getDateTraining(article.stockLastModified)}</span>
                                    </label>
                                    <label>
                                      Diff
                                      <span>{article.stockDiff * -1} de moins</span>
                                    </label>
                                  </div>
                            */}
                          </div>

                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          {article.photoUrl &&
                            getPhotoArticle(article.photoUrl, article.title, {
                              maxHeight: "300px",
                              maxWidth: "300px",
                              height: "300px",
                              width: "300px",
                              borderRadius: "5px",
                            })
                          }
                          <span style={{ marginLeft: "10px" }}>{article.description}</span>
                        </div>
                        {mouvementStock && (
                          <div style={{ marginTop: "10px", maxHeight: "300px", overflow: "auto" }}>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "5px 10px",
                              width: "100%",
                              backgroundColor: "rgb(198 221 198)",
                              boxShadow: "0px 3px 8px 0px #e3e3e3",
                              position: "sticky",
                              top: "0px",
                              borderRadius: "3px"
                            }}><b>Historique</b> <span>Stock : <b>{article.qte}</b></span></div>

                            {mouvementStock &&
                              mouvementStock.length === 0 ?
                              <span style={{
                                padding: "5px 20px",
                              }}>Aucun mouvement</span>

                              : mouvementStock.map((mouv, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", flexDirection: "column", fontSize: "0.8em", width: "100%" }}>
                                        <span style={{ fontWeight: "bold", minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                          {mouv["motif"]}
                                        </span>

                                        {mouv["modifiedBy"] &&
                                          <label className="labelKeyValue" style={{ fontSize: "0.8em" }} >
                                            <div>
                                              {mouv["modifiedBy"] &&
                                                <span> {(user && mouv["modifiedBy"].uid === user?.uid) ? "Vous" : mouv["modifiedBy"].displayName + " " + mouv["modifiedBy"].lastName}</span>
                                              }
                                              {mouv["date"] &&
                                                <>
                                                  <span>|</span>
                                                  <span>{getDateTraining(mouv["date"])}</span>
                                                </>
                                              }
                                            </div>
                                          </label>
                                        }
                                        {mouv["licencie"] &&
                                          <label className="labelKeyValue" style={{ fontSize: "0.8em" }} >
                                            <div>
                                              {mouv["licencie"] &&
                                                <span> Vendu à {(user && mouv["licencie"].uid === user?.uid) ? "Vous" : (mouv["licencie"].displayName + " " + mouv["licencie"].lastName)}</span>
                                              }
                                              {mouv["date"] &&
                                                <>
                                                  <span>|</span>
                                                  <span>{getDateTraining(mouv["date"])}</span>
                                                </>
                                              }
                                            </div>
                                          </label>
                                        }
                                      </div>
                                      <span style={{ color: mouv["typeMouvement"] === TypeMouvement.DEPOT ? "green" : "darkred", fontWeight: "bold", minWidth: "70px", textAlign: "right" }}>
                                        {(mouv["motif"] === TypeMotifStock.VERIFICATION ? "" : (mouv["typeMouvement"] === TypeMouvement.DEPOT ? "+ " : " ")) + mouv["qte"]}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </>
          )}

      </Container>

      {
        db.isAdmin ?
          <Container>
            <h3
              className="section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Purge
            </h3>
            <div
              style={{
                width: "100%",
              }}
            >
              <Form
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <FloatingLabel
                  label="Avant (nb semaines)"
                  style={{ flex: "1 1 auto", width: "100%" }}
                >
                  <Form.Control
                    type="number"
                    step="1"
                    min="52"
                    max="104"
                    style={{ width: "100%" }}
                    value={nbWeekBefore}
                    required
                    onChange={(e) => {
                      setNbWeekBefore(e.target.value)
                    }}
                  />
                </FloatingLabel>
                <Button
                  className="btn btn-custom5"
                  style={{ width: "100%" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleShowConfirmation(
                      "Purger les anciens mouvements",
                      "Voulez-vous supprimer les anciens mouvements ? Le stock restera inchangé.",
                      "danger",
                      "Supprimer",
                      "Annuler",
                      null,
                      onValidPurgeMouvements
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      marginRight: "5px"
                    }}
                  />
                  Retirer les anciens mouvements
                </Button>
              </Form>
            </div>
          </Container> : null
      }

      <ModalArticle
        show={modalArticleShow}
        currentArticleEdit={currentArticleEdit}
        onHide={() => setModalArticleShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </Container >
  );
}

export default ManagementBoutique;
