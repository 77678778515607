import React, { useState, useEffect, useCallback } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Accordion, Placeholder } from "react-bootstrap";
import ManagementTrainings from "./ManagementTrainings.js";
import {
  onSnapshot,
  query,
  collection
} from "firebase/firestore";
import ModalTraining from "./ModalTraining.js";
function Trainings() {
  const { db, DaysOfWeek, getLicencie, EventType } = useGlobalContext();
  const [creneauxAdulte, setCreneauxAdulte] = useState([]);
  const [creneauxJeune, setCreneauxJeune] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTraining, setCurrentTraining] = useState(null);
  const [modalTrainingShow, setModalTrainingShow] = useState(false);
  const [modalShowTrainingShow, setModalShowTrainingShow] = useState(false);


  const onValid = () => {
    setModalTrainingShow(false);
  };

  const onCancel = () => {
    setModalTrainingShow(false);
  };

  const onClose = () => {
    setModalShowTrainingShow(false);
  };

  const getEncadrantsCreneau = useCallback(async (creneau) => {
    const retour = [];
    for (const encUid of creneau["encadrants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    };
    return retour;
  }, [getLicencie]);

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/créneaux"));
    const unsubscribeCreneau = onSnapshot(q, async (querySnapshotCreneau) => {
      let traningsAdulte = [];
      let traningsJeune = [];
      await Promise.all(
        querySnapshotCreneau.docs.map(async (doc) => {
          const creneau = doc.data();
          creneau["encadrants"] = await getEncadrantsCreneau(creneau);
          if (creneau.type === EventType.ADULTE_TRAINING) {
            const creneauFindIndex = traningsAdulte.findIndex(cre => cre.uid === creneau.uid);
            if (creneauFindIndex > -1) {
              traningsAdulte[creneauFindIndex] = creneau;
            } else {
              traningsAdulte.push(creneau);
            }
          } else if (creneau.type === EventType.YOUNG_TRAINING) {
            const creneauFindIndex = traningsJeune.findIndex(cre => cre.uid === creneau.uid);
            if (creneauFindIndex > -1) {
              traningsJeune[creneauFindIndex] = creneau;
            } else {
              traningsJeune.push(creneau);
            }
          }
        }));
      traningsAdulte = traningsAdulte.sort((a, b) =>
        a.type.toLowerCase() + a.jour > b.type.toLowerCase() + b.jour ? 1 : -1
      )
      traningsJeune = traningsJeune.sort((a, b) =>
        a.type.toLowerCase() + a.jour > b.type.toLowerCase() + b.jour ? 1 : -1
      )
      setCreneauxAdulte([...traningsAdulte]);
      setCreneauxJeune([...traningsJeune]);
      setLoading(false);
    });

    return () => {
      unsubscribeCreneau();
    };

  }, [db, getEncadrantsCreneau, EventType]);

  return (
    <Container>
      <h3
        className="section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <span style={{ flex: "1 1 auto" }}>Créneau{creneauxAdulte.length > 1 && "x"} adulte</span>
        <span style={{ textAlign: "right", maxWidth: "150px", fontSize: "0.5em" }}>à partir de 14 ans accompagné</span>
      </h3>
      <Container style={{ marginBottom: "20px" }}>
        <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
          {loading ?
            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
              <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
            </Placeholder>
            : creneauxAdulte.length === 0 ? (
              <span style={{ fontStyle: "italic" }}>Aucun créneau</span>
            ) : (
              creneauxAdulte.map((creneau, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          paddingRight: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            width: "100%"
                          }}
                        >
                          <span style={{
                            margin: "0px 0px",
                            marginBottom: "5px",
                            lineHeight: "2em",
                          }}>
                            Tous les <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 2px",
                            }}>{DaysOfWeek[creneau.jour]}s</b>
                            <br />
                            de <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 5px",
                            }}>{creneau.heureDebut.replace(":", "h")}</b> à <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 5px",
                            }}>{creneau.heureFin.replace(":", "h")}</b>
                            <br />
                            à la <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 2px",
                            }}>{creneau.lieu}</b>
                          </span>
                        </div>
                        {creneau["encadrants"].length > 0 &&
                          <label style={{ width: "-webkit-fill-available", backgroundColor: "rgb(230, 230, 230)", margin: "10px 0px" }}>
                            Encadrants
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                              {creneau["encadrants"].length !== 0 ? (creneau["encadrants"].map((encadrant, indexP) => (
                                <span key={indexP} >
                                  {encadrant.displayName}
                                </span>
                              ))) : (
                                <span>-</span>
                              )}
                            </div>
                          </label>}
                      </div>

                    </Accordion.Header>
                    <Accordion.Body style={{
                      backgroundColor: "rgb(190, 190, 190)",
                      fontSize: "0.9em"
                    }}>

                      <ManagementTrainings
                        nbTrainingVisible={4}
                        fromNow={true}
                        creneau={creneau}
                        setCurrentTraining={setCurrentTraining}
                        setModalShowTrainingShow={setModalShowTrainingShow}
                        setModalTrainingShow={setModalTrainingShow}
                      ></ManagementTrainings>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            )}
        </Accordion>
      </Container>

      <h3
        className="section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <span style={{ flex: "1 1 auto" }}>Créneau{creneauxJeune.length > 1 && "x"} jeune</span>
        <span style={{ textAlign: "right", maxWidth: "150px", fontSize: "0.5em" }}>de 8 à 14 ans</span>
      </h3>
      <Container style={{ marginBottom: "20px" }}>
        <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
          {loading ?
            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
              <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
            </Placeholder>
            : creneauxJeune.length === 0 ? (
              <span style={{ fontStyle: "italic" }}>Aucun créneau</span>
            ) : (
              creneauxJeune.map((creneau, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          paddingRight: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            width: "100%"
                          }}
                        >
                          <span style={{
                            margin: "0px 0px",
                            marginBottom: "5px",
                            lineHeight: "2em",
                          }}>
                            Tous les <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 2px",
                            }}>{DaysOfWeek[creneau.jour]}s</b>
                            <br />
                            de <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 5px",
                            }}>{creneau.heureDebut.replace(":", "h")}</b> à <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 5px",
                            }}>{creneau.heureFin.replace(":", "h")}</b>
                            <br />
                            à la <b style={{
                              backgroundColor: "#e2f0e2",
                              padding: "3px",
                              margin: "0px 2px",
                            }}>{creneau.lieu}</b>
                          </span>
                        </div>

                        {creneau["encadrants"].length > 0 &&
                          <label style={{ width: "-webkit-fill-available", backgroundColor: "rgb(230, 230, 230)", margin: "10px 0px" }}>
                            Encadrant{creneau["encadrants"].length > 1 && "s"}
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                              {creneau["encadrants"].length !== 0 ? (creneau["encadrants"].map((encadrant, indexP) => (
                                <span key={indexP} >
                                  {encadrant.displayName}
                                </span>
                              ))) : (
                                <span>-</span>
                              )}
                            </div>
                          </label>}
                      </div>

                    </Accordion.Header>
                    <Accordion.Body style={{
                      backgroundColor: "rgb(190, 190, 190)",
                      fontSize: "0.9em"
                    }}>

                      <ManagementTrainings
                        nbTrainingVisible={4}
                        fromNow={true}
                        creneau={creneau}
                        setCurrentTraining={setCurrentTraining}
                        setModalShowTrainingShow={setModalShowTrainingShow}
                        setModalTrainingShow={setModalTrainingShow}
                      ></ManagementTrainings>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            )}
        </Accordion>
      </Container>

      <ModalTraining
        show={modalTrainingShow}
        edit={true}
        training={currentTraining}
        onHide={() => setModalTrainingShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />

      <ModalTraining
        show={modalShowTrainingShow}
        edit={false}
        training={currentTraining}
        onHide={() => setModalShowTrainingShow(false)}
        onCancel={onClose}
      />
    </Container >
  );
}

export default Trainings;
