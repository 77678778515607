import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
function Partners() {
  const partners = [{
    name: "AB Création",
    img: "./abcreation.png",
    webSite: "",
    linkedIn: "https://fr.linkedin.com/in/aur%C3%A9lien-ballay-a594b51b2",
    facebook: "https://www.facebook.com/profile.php?id=100083195144187"
  }, {
    name: "Knowllence",
    img: "./knowllence.png",
    webSite: "www.knowllence.com",
    linkedIn: "https://www.linkedin.com/company/knowllence",
    facebook: "https://www.facebook.com/knowllence"
  }, {
    name: "Crédit agricole",
    img: "./CA.png",
    webSite: "https://www.credit-agricole.fr/",
    linkedIn: "",
    facebook: ""
  }, {
    name: "Cave de la joux",
    img: "./cavedelajoux.jpg",
    webSite: "",
    linkedIn: "",
    facebook: "https://www.facebook.com/cavedelajoux"
  }, {
    name: "Cuenot Energies",
    img: "./cuenot.svg",
    webSite: "https://www.cuenot-energies.com/",
    linkedIn: "",
    facebook: "https://www.facebook.com/cuenotenergies/"
  }, {
    name: "Mairie de levier",
    img: "./mairieLevier.jpg",
    webSite: "https://www.levier.fr/",
    linkedIn: "",
    facebook: "https://www.facebook.com/levier.fr/?locale=fr_FR"
  }];

  const partnersSportifs = [{
    name: "Lardesport",
    img: "./lardesport.png",
    webSite: "https://www.lardesports.com/",
    linkedIn: "",
    facebook: ""
  }]



  return <Container style={{
  }}>
    <h3
      className="section"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      Sponsors
    </h3>
    <Container style={{
    }}>
      <p style={{ fontWeight: "bold", textAlign: "center" }}>Merci à nos sponsors pour leur aide dans le développement dans notre club !</p>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {partners.map((partner, index) => {
          return (
            <div
              key={index}
              style={{
                flex: "1 1 auto",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
                boxShadow: "0px 0px 10px 2px gray",
                marginBottom: "10px",
                backgroundColor: "white",
                borderRadius: "5px"
              }}
            >
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
                width: "100%",
                margin: "20px"
              }}>
                <h3>
                  <b>{partner.name}</b>
                </h3>
                <img alt="" style={{
                  borderRadius: "5px",
                  //boxShadow: "2px 2px 10px 0px gray",
                  maxHeight: "100px",
                  padding: "10px",
                  maxWidth: "300px"
                }} src={partner.img} ></img>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "bold",
                  width: "100%",
                  backgroundColor: "rgb(226 240 226)",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px"
                }}
              >

                {partner.webSite !== "" &&
                  <a className="btn-custom6" href={partner.webSite} style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}>
                    <FontAwesomeIcon
                      style={{ marginRight: "5px" }}
                      icon={faGlobe}
                    /><span>Web</span>
                  </a>
                }
                {partner.facebook !== "" &&
                  <a className="btn-custom6" href={partner.facebook} style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}>
                    <i style={{ marginRight: "5px" }} className="bi bi-facebook"></i>
                    <span>Facebook</span>
                  </a>
                }
                {partner.linkedIn !== "" &&
                  <Link className="btn-custom6" to={partner.linkedIn} style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}>
                    <i style={{ fontSize: "1.5em", marginRight: "10px" }} className="bi bi-linkedin"></i>
                    <span>LinkedIn</span>
                  </Link>
                }
              </div>
            </div>
          )
        })}
      </div>
    </Container>
    <h3
      className="section"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      Partenaire sportifs
    </h3>
    <Container style={{
    }}>
      <p style={{ fontWeight: "bold", textAlign: "center" }}>
        Nous collaborons avec Lardesport en ce qui concerne l'achat de matériel de Badminton.
      </p>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {partnersSportifs.map((partner, index) => {
          return (
            <div
              key={index}
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
                boxShadow: "0px 0px 10px 2px gray",
                marginBottom: "10px",
                backgroundColor: "white",
                borderRadius: "5px"
              }}
            >
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
                width: "100%",
                margin: "20px"
              }}>
                <h3>
                  <b>{partner.name}</b>
                </h3>
                <img alt="" style={{
                  borderRadius: "5px",
                  boxShadow: "2px 2px 10px 0px gray",
                  maxHeight: "100px", maxWidth: "300px"
                }} src={partner.img} ></img>
              </div>
              <h4 style={{ textAlign: "center" }}>
                Code promo <br />pour les licenciés du club
              </h4>
              <span style={{
                fontSize: "2em", fontWeight: "bold", borderRadius: "5px", border: "1px solid gray", padding: "5px", marginTop: "10px", marginBottom: "10px", backgroundColor: "lightgray"
              }}>FRBC25015</span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "bold",
                  width: "100%",
                  backgroundColor: "rgb(226 240 226)",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px"
                }}
              >

                {partner.webSite !== "" &&
                  <a className="btn-custom6" href={partner.webSite} style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}>
                    <FontAwesomeIcon
                      style={{ marginRight: "5px" }}
                      icon={faGlobe}
                    /><span>Site web</span>
                  </a>
                }
                {partner.facebook !== "" &&
                  <Link to={partner.facebook}>
                    <i style={{ marginRight: "5px" }} className="bi bi-facebook"></i>
                    Facebook
                  </Link>
                }
                {partner.linkedIn !== "" &&
                  <Link className="btn-custom6" to={partner.linkedIn} style={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}>
                    <i style={{ fontSize: "1.5em", marginRight: "10px" }} className="bi bi-linkedin"></i>
                    <span>Accès LinkedIn</span>
                  </Link>
                }
              </div>
            </div>
          )
        })}
      </div>
    </Container>

  </Container >;
}

export default Partners;
