import React, { useState, useEffect } from "react";
import { Button, Offcanvas, Form, Badge } from "react-bootstrap";
import { useGlobalContext } from "./GlobalContext";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingButton from "./LoadingButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faCog,
  faUsers,
  faUsersLine,
  faArrowRightToBracket,
  faWallet,
  faShoppingCart,
  faRefresh,
  faCalendarAlt,
  faFolderOpen
} from "@fortawesome/free-solid-svg-icons";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit
} from "firebase/firestore";
function OffCanvasRight({ borderOffcanvasRight, theme, toggleTheme, show, handleShow, handleClose, handleCloseLeft }) {
  const {
    PageUrl,
    auth,
    db,
    user,
    getPhoto,
    handleShowAlert,
    handleSendMailVerif,
    handleShowConfirmation,
    handleCloseConfirmation,
    isPwa,
    dbAvailable,
    getLicencie,
    TypeMouvement,
    StatusMouvement
  } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [porteFeuille, setPorteFeuille] = useState(null);
  const [mouvementsDepotEncours, setMouvementsDepotEncours] = useState([]);
  const [mouvementsAchatEncours, setMouvementsAchatEncours] = useState([]);
  const role = (
    <span
      style={{
        fontSize: "0.5em",
        color: "gray"
      }}
    >
      {db.isAdmin ? (
        <>
          Administrateur <br />
        </>
      ) : null}
      {db.isMemberBureau ? (
        <>
          Membre du bureau <br />
        </>
      ) : db.isMemberClub ? (
        <>
          Membre du club <br />
        </>
      ) : null}
    </span>
  );

  const confirmLogOut = async () => {
    handleClose();
    await auth.logout();
    await db.fetchUserRights();
    navigate(PageUrl.HOME, { to: PageUrl.HOME });
    handleCloseConfirmation();
  }

  const handleLogout = async () => {
    handleShowConfirmation(
      "A bientôt",
      `Vous nous quittez déjà ?`,
      "success",
      "Oui",
      "Non",
      null,
      confirmLogOut
    );
  };

  const onHandleSignalLicencie = async () => {
    await db.signalLicencie(user);
    await this.fetchUserRights(user, true);
    handleShowAlert("Votre demande a été envoyée", "Un membre du bureau doit accepter cotre demande.", "warning");
    handleClose();
    //handleShow();
  };
  const stylePwaUser = isPwa ? { borderBottom: borderOffcanvasRight, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" } : { fontSize: "1.5em", display: "flex" };

  let badgeCoffre = 0;

  let badgeAchatCompt = 0;
  let badgeDepotCompt = 0;
  let badgePhotoUser = 0;

  let badgeAllAchatCompt = 0;
  let badgeAllDepotCompt = 0;
  let badgeAll = 0;

  if (user) {
    if (db.isMemberBureau) {
      badgeAchatCompt = mouvementsAchatEncours.filter(m => m.licencie?.uid === user?.uid).length;
      badgeDepotCompt = mouvementsDepotEncours.filter(m => m.licencie?.uid === user?.uid).length;
      badgePhotoUser = badgeAchatCompt + badgeDepotCompt;
      if (db.isMemberBureau || db.isAdmin) {
        badgeAllAchatCompt = mouvementsAchatEncours.filter(m => (m.status === StatusMouvement.ATTENTE_DEPOT && m.remiseMembreBureau && m.remiseMembreBureau?.uid === user?.uid) || (m.status === StatusMouvement.ATTENTE_VALIDATION || m.status === StatusMouvement.ATTENTE_DISTRIBUTION)).length;
        badgeAllDepotCompt = mouvementsDepotEncours.filter(m => (m.status === StatusMouvement.ATTENTE_DEPOT && m.remiseMembreBureau && m.remiseMembreBureau?.uid === user?.uid) || m.status === StatusMouvement.ATTENTE_VALIDATION).length;
        badgeAll = badgeAllAchatCompt + badgeAllDepotCompt;

        badgeCoffre = mouvementsDepotEncours.filter(m => m.status === StatusMouvement.ATTENTE_VALIDATION && m.dansCoffre && !m.alreadyRetirerCoffre).reduce((accumulateur, t) => accumulateur + t["montant"], 0) +
          mouvementsAchatEncours.filter(m => m.status === StatusMouvement.ATTENTE_VALIDATION && m.dansCoffre && !m.alreadyRetirerCoffre).reduce((accumulateur, t) => accumulateur + t["montant"], 0);
      } else {
        badgeAllAchatCompt = mouvementsAchatEncours.filter(m => (m.status === StatusMouvement.ATTENTE_DEPOT && m.remiseMembreBureau && m.remiseMembreBureau?.uid === user?.uid) || m.status !== StatusMouvement.ATTENTE_VALIDATION).length;
        badgeAllDepotCompt = mouvementsDepotEncours.filter(m => (m.status === StatusMouvement.ATTENTE_DEPOT && m.remiseMembreBureau && m.remiseMembreBureau?.uid === user?.uid) || m.status !== StatusMouvement.ATTENTE_VALIDATION).length;
        badgeAll = badgeAllAchatCompt + badgeAllDepotCompt;
      }
    } else {
      badgeAchatCompt = mouvementsAchatEncours.length;
      badgeDepotCompt = mouvementsDepotEncours.length;
      badgePhotoUser = badgeAchatCompt + badgeDepotCompt;
    }
  }

  useEffect(() => {
    let unsubscribe = null;
    let unsubscribeMouvementsEncours = null;
    if (user) {
      const q = query(collection(db.db, "clubs/badlevier/licenciés"), where("uid", "==", user?.uid), limit(1));
      unsubscribe = onSnapshot(q, async (querySnapshot) => {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const currentUser = doc.data();
            setPorteFeuille(currentUser["porte-feuille"]);
          }));
      });
      if (db.isMemberClub) {
        let q1;
        if (db.isMemberBureau) {
          q1 = query(collection(db.db, "clubs/badlevier/mouvements"));
        } else {
          q1 = query(collection(db.db, "clubs/badlevier/mouvements"), where("licencie", "==", user?.uid));
        }
        //const q = query(collection(db.db, "clubs/badlevier/licenciés/" + user?.uid + "/mouvements"));
        unsubscribeMouvementsEncours = onSnapshot(q1, async (querySnapshotMouvemementsEncours) => {
          const retour2Depot = [];
          const retour2Achat = [];
          await Promise.all(
            querySnapshotMouvemementsEncours.docs.map(async (doc) => {
              const mouvement = doc.data();
              mouvement["licencie"] = await getLicencie(mouvement.licencie);
              mouvement["createur"] = await getLicencie(mouvement.createur);
              mouvement["remiseMembreBureau"] = await getLicencie(mouvement.remiseMembreBureau);
              if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
                const mouvementFindIndex = retour2Depot.findIndex(mouv => mouv?.uid === mouvement?.uid);
                if (mouvementFindIndex > -1) {
                  retour2Depot[mouvementFindIndex] = mouvement;
                } else {
                  retour2Depot.push(mouvement);
                }
              } else {
                const mouvementFindIndex = retour2Achat.findIndex(mouv => mouv?.uid === mouvement?.uid);
                if (mouvementFindIndex > -1) {
                  retour2Achat[mouvementFindIndex] = mouvement;
                } else {
                  retour2Achat.push(mouvement);
                }
              }
            }));
          setMouvementsDepotEncours([...retour2Depot]);
          setMouvementsAchatEncours([...retour2Achat]);
        });
      }
    }

    return () => {
      if (unsubscribe) unsubscribe();
      if (unsubscribeMouvementsEncours) unsubscribeMouvementsEncours();
    };
  }, [db, db.isLicencie, user, TypeMouvement, getLicencie])

  return (
    <div className="buttonMenuPwa" style={{ display: "flex", alignItems: isPwa ? "flex-start" : "center", height: "100%" }}>
      {user ? (
        <div className={dbAvailable && "dbNotAvailable"} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div
            style={stylePwaUser}
            className="buttonPwa"
            onClick={
              () => {
                handleCloseLeft();
                if (show) {
                  handleClose();
                } else {
                  handleShow();
                }
              }
            }
          >
            {getPhoto(user, {
              height: "32px", width: "32px", borderRadius: "50%", margin: "0px", border: "1px solid gray",
            },
              badgePhotoUser > 0 && <Badge bg="warning" style={{ position: "absolute", top: "-2px", left: "-14px", border: "1px solid gray", padding: "3px 5px", color: "black" }}>{badgePhotoUser}</Badge>
            )}
            {isPwa ? <span style={{ fontSize: "0.8em" }}>Vous</span> : <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{ marginLeft: "10px", height: "1.3em", marginRight: "10px" }}
            />}
          </div>
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            name="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Bienvenue</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  paddingBottom: "50px",
                }}
              >
                <div style={{ width: "100%", flex: "1 1 auto" }}>
                  <ul>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          onClick={() => {
                            handleClose();
                            navigate(PageUrl.EDIT_PROFIL + "/informations", { to: PageUrl.EDIT_PROFIL });
                          }}
                          style={{
                            display: "flex",
                            width: "-webkit-fill-available",
                            alignItems: "flex-start",
                            margin: "10px",
                          }}>
                          <div style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              {getPhoto(user, {
                                height: "48px",
                                borderRadius: "50%",
                                border: "1px solid gray"
                              })}
                              {user.displayName ? (
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1 1 auto",
                                    marginLeft: "20px",
                                    fontSize: "1.5em"
                                  }}
                                >
                                  {user.displayName}
                                  {role ? role : null}
                                </span>
                              ) : (
                                <span>Compléter mon profil</span>
                              )}
                            </div>

                          </div>
                          <Button className="btn btn-custom1">
                            <FontAwesomeIcon
                              icon={faCog}
                              style={{ fontSize: "2em", margin: "0px" }}
                            />
                          </Button>
                        </div>
                        {db.isMemberClub && (
                          <div
                            className="btn btn-custom1"
                            onClick={() => {
                              handleClose();
                              navigate(PageUrl.EDIT_PROFIL + "/commandes", { to: PageUrl.EDIT_PROFIL });
                            }}
                            style={{
                              fontSize: '1.3em',
                              paddingRight: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "-webkit-fill-available"
                            }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "5px"
                              }}>
                              <FontAwesomeIcon
                                icon={faShoppingCart}
                                style={{ marginRight: "10px" }}
                              />
                              <span>Commandes</span>
                              {badgeAchatCompt > 0 && <Badge bg="warning" style={{ marginLeft: "6px", color: "black", border: "1px solid gray", padding: "3px 5px" }}>{badgeAchatCompt}</Badge>}
                            </div>
                          </div>

                        )}
                        {db.isMemberClub && (
                          <div
                            className="btn btn-custom1"

                            onClick={() => {
                              handleClose();
                              navigate(PageUrl.EDIT_PROFIL + "/porteFeuille", { to: PageUrl.EDIT_PROFIL });
                            }}
                            style={{
                              fontSize: '1.3em',
                              paddingRight: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "-webkit-fill-available"
                            }}>
                            <div style={{
                              display: "flex",
                              margin: "5px",
                              justifyContent: "space-between", alignItems: "center"
                            }}>
                              <FontAwesomeIcon
                                icon={faWallet}
                                style={{ marginRight: "10px" }}
                              />
                              <span>Portefeuille</span>
                              {badgeDepotCompt > 0 && <Badge bg="warning" style={{ marginLeft: "6px", color: "black", border: "1px solid gray", padding: "3px 5px" }}>{badgeDepotCompt}</Badge>}
                            </div>
                            <span>{porteFeuille ? porteFeuille.toFixed(2) + "€" : "0.00 €"}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <li>
                      {user && !db.isUserVerified ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            textAlign: "center",
                            flex: "1 1 auto",
                          }}
                        >

                          <p style={{ fontSize: "0.8em", margin: "0px" }}>
                            Un email a été envoyé à cette adresse

                          </p>
                          <span
                            style={{
                              padding: "5px",
                              borderRadius: "3px",
                              textDecoration: "",
                            }}
                          >
                            <a
                              style={{
                                fontSize: "0.8em",
                                textAlign: "center",
                                width: "100%",
                                textDecoration: "underline !important",
                                color: "#1a67df",
                              }}
                              href={"mailto:" + user.email}
                            >
                              {user.email}
                            </a>
                          </span>

                          <p style={{ fontSize: "0.7em" }}>
                            Vous n'avez pas reçu l'email ?
                          </p>
                          <LoadingButton
                            className="btn btn-custom2"
                            onClickPromise={async () => {
                              await handleSendMailVerif(auth.auth.currentUser).then(() => {
                                handleClose();
                                return true;
                              });
                            }}
                            contentLoading="Envoi du mail..."
                          >
                            Renvoyer le mail
                          </LoadingButton>
                          <LoadingButton
                            className="btn btn-custom"
                            onClickPromise={async () => {
                              window.location.reload();
                            }}
                            contentLoading="..."
                          >

                            <FontAwesomeIcon
                              icon={faRefresh}
                            />
                            Rafraichir la page
                          </LoadingButton>

                          <p style={{ fontSize: "0.8em" }}></p>
                          <span
                            style={{
                              fontSize: "0.6em",
                              fontStyle: "italic",
                              color: "gray",
                            }}
                          >
                            Cliquez sur le lien à l'intérieur du mail pour
                            valider votre compte. <br /> N'oubliez pas de
                            vérifier dans le courrier indésirable
                          </span>


                        </div>
                      ) : null}
                      {!db.isLicencie && db.isUserVerified ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{ fontSize: "0.8em" }}>
                            Vous êtes du club ?
                          </span>
                          <LoadingButton
                            className="btn btn-custom2"
                            onClickPromise={onHandleSignalLicencie}
                          >
                            Me signaler !
                          </LoadingButton>
                        </div>
                      ) : null}
                      {db.isLicencie && !db.isMemberClub ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "0.7em", textAlign: "center" }}
                          >
                            Vous avez signalé appartenir au club, un membre du
                            bureau validera votre demande dans les plus brefs
                            délais
                          </span>
                        </div>
                      ) : null}
                    </li>

                    {(db.isAdmin || db.isMemberBureau) && (
                      <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", border: "1px solid green", borderRadius: "5px", padding: "5px", boxShadow: "3px 3px 10px 1px gray" }}>
                        <li style={{ fontSize: "1em", color: "gray", marginBottom: "5px" }}>Gestion</li>
                        <ul style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "10px", padding: "5px" }}>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_TRESORERIE}
                              onClick={handleClose}
                              style={{ position: "relative" }}
                            >
                              <FontAwesomeIcon
                                icon={faWallet}
                                style={{ maringRight: "10px" }}
                              />
                              Trésorerie
                              {badgeAll > 0 && <Badge bg="warning" style={{ position: "absolute", top: "12px", right: "133px", border: "1px solid gray", padding: "3px 5px", color: "black" }}>{badgeAll}</Badge>}
                              {db.isMemberBureau &&
                                <Badge bg="warning" style={{ position: "absolute", top: "-23px", right: "13px", border: "1px solid gray", padding: "3px 5px", color: "black" }}>
                                  Contenu du coffre : {badgeCoffre} €
                                </Badge>
                              }
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_BOUTIQUE}
                              onClick={handleClose}
                            >
                              <FontAwesomeIcon
                                icon={faShoppingCart}
                                style={{ maringRight: "10px" }}
                              />
                              Boutique
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_LICENCES}
                              onClick={handleClose}
                            >
                              <FontAwesomeIcon
                                icon={faUsers}
                                style={{ maringRight: "10px" }}
                              />
                              Licences
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_BUREAU}
                              onClick={handleClose}
                            >
                              <FontAwesomeIcon
                                icon={faUsersLine}
                                style={{ maringRight: "10px" }}
                              />
                              Bureau
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_CRENEAUX}
                              onClick={handleClose}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="rgb(28, 105, 28)"
                                  d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
                                />
                              </svg>
                              Créneaux
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_EQUIPES}
                              onClick={handleClose}
                            >
                              <i className="bi bi-trophy"></i>
                              Equipes
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_EVENTS}
                              onClick={handleClose}
                            >
                              <FontAwesomeIcon
                                icon={faCalendarAlt}
                                style={{ maringRight: "10px" }}
                              />
                              Evènements
                            </Link>
                          </li>
                          <li style={{ width: "100%" }}>
                            <Link
                              to={PageUrl.MANAGEMENT_DOCUMENTS}
                              onClick={handleClose}
                            >
                              <FontAwesomeIcon
                                icon={faFolderOpen}
                                style={{ maringRight: "10px" }}
                              />
                              Documents
                            </Link>
                          </li>
                        </ul>
                      </ul>
                    )}
                  </ul>

                  <Form.Check
                    type="switch"
                    className="switchDarkMode"
                    label="Mode sombre"
                    checked={theme === "dark"}
                    onChange={toggleTheme}
                  />
                </div>

                <LoadingButton
                  className="btn btn-custom2"
                  //style={{ position: "sticky", bottom: "0px" }}
                  onClickPromise={handleLogout}
                >
                  Déconnexion
                </LoadingButton>
              </div>
            </Offcanvas.Body >
          </Offcanvas >
        </div >
      ) : location.pathname === PageUrl.SIGNIN && !isPwa ? (
        <></>
      ) : isPwa ? (
        <Link
          className="buttonMenuPwa"
          style={stylePwaUser}
          to={PageUrl.SIGNIN}>
          <FontAwesomeIcon
            icon={faArrowRightToBracket}
            style={{ height: "32px", padding: "2px" }}
          />
          {isPwa ? <span style={{ fontSize: "0.8em" }}>Connexion</span> : <FontAwesomeIcon
            icon={faEllipsisVertical}
            style={{ marginLeft: "10px" }}
          />}
        </Link>
      ) : (
        <div style={{ display: "flex" }}>
          <Link to={PageUrl.SIGNIN} className="btn btn-custom2 mx-2">
            Connexion
          </Link>
        </div>
      )
      }
    </div >
  );
}

export default OffCanvasRight;
