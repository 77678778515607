import React from "react";
import { Container, Button } from "react-bootstrap";
import ModalImageCustom from "./ModalImageCustom.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
function Contact() {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px auto",
          paddingTop: "20px"
        }}
      >
        <h5>Salle des sports de Levier</h5>
        <div style={{ marginBottom: "10px" }}>
          Rue de Champagnole <br />25270 Levier
          <iframe
            title="mapSalleDesSports"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5447.489652614686!2d6.10421919357911!3d46.94705409999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478d0b17724d4a89%3A0x6791c3d1b88b1291!2sCENTRE%20SPORTIF!5e0!3m2!1sfr!2sfr!4v1697438183422!5m2!1sfr!2sfr"
            width="100%"
            height="200"
            style={{ borderRadius: "5px", border: "gray solid 2px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <h5>Contactez-nous</h5>
        <Container>
          <Button style={{ textDecoration: "none", width: "300px", height: "60px" }} className="btn btn-custom1">
            <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  fontSize: "2em",
                  marginRight: "10px",
                  color: "green",
                }}
              />
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-around", height: "100%" }}>                <span style={{ fontSize: "1.5em" }}>Par mail</span>
                <a style={{ color: "green", textDecoration: "none" }} href="mailto:badlevier@gmail.com">badlevier@gmail.com</a>
              </div>
            </div>
          </Button>
          <Button href="https://www.facebook.com/profile.php?id=100064846594124"
            style={{ textDecoration: "none", width: "300px", height: "60px" }} className="btn btn-custom1">
            <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center" }}>
              <i style={{ fontSize: "2em", marginRight: "5px" }} className="bi bi-facebook"></i>
              <div style={{ display: "flex", marginLeft: "10px", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", height: "100%" }}>
                <span style={{ fontSize: "1.5em", marginBottom: "10px" }}>Sur Facebook</span>
              </div>
            </div>
          </Button>
          <Button
            href="https://chat.whatsapp.com/LDGSXKShcVq27P3nDWcMOs"
            style={{ textDecoration: "none", width: "300px", height: "60px" }} className="btn btn-custom1">
            <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center" }}>
              <i className="bi bi-whatsapp" style={{ fontSize: "2em", marginRight: "5px" }}></i>
              <div style={{ display: "flex", marginLeft: "10px", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", height: "100%" }}>
                <span style={{ fontSize: "1.5em", marginBottom: "10px" }}>Sur Whatsapp</span>

              </div>
            </div>
          </Button>

          <div style={{ display: "flex", marginLeft: "10px", alignItems: "center", justifyContent: "flex-start" }}>
            <ModalImageCustom
              className="qrCodeBadlevier"
              small="./qrCodeBadlevier.svg"
              large="./qrCodeBadlevier.svg"
              alt="Communauté WhatsApp Badlevier"
              style={{ width: "50px", height: "50px" }}
            />
            <span style={{ fontSize: "0.8em", width: "150px", marginLeft: "10px" }}>Scannez le QR code pour rejoindre la communauté Whatsapp</span>
          </div>

        </Container>
      </div >
      <span></span>
    </Container >
  );
}

export default Contact;
