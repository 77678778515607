import React, { useState, useEffect } from 'react';
import { Container, Accordion, Placeholder, Button } from "react-bootstrap";
import { useGlobalContext } from "../GlobalContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getTournoi, Tour/*, StateTournoi*/ } from "./ModelTournois.js";

function PageTournoi() {
    const { handleShowConfirmation, getMiniUserPresentation, handleCloseConfirmation } = useGlobalContext();
    const [loading, setLoading] = useState(true);
    const { tournoiUid } = useParams();
    const tournoi = getTournoi(tournoiUid);
    const tours = tournoi && tournoi.tours;

    const addTour = async (tournament) => {
        tournoi.tours.push(new Tour());
        tournoi.save();
    }

    const deleteTour = async (tournament, tourIndex) => {
        tournoi.tours.splice(tourIndex, 1);
        tournoi.save();
        handleCloseConfirmation();
    }

    /*const lancerTournoi = () => {
        handleShowConfirmation(
            "",
            "Lancer le tournoi ?",
            "success",
            "C'est parti",
            "Annuler",
            null,
            () => {
                tournoi.state = StateTournoi.ENCOURS;
                tournoi.save();
            }
        );
    }*/

    const getTerrain = (terrain) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {terrain.equipes.map((equipe, index) => {
                    return (
                        <div key={"equipe" + index} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            {equipe.joueurs.map((joueur, index) => {
                                return (
                                    <div key={"joueur" + index} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        {getMiniUserPresentation(
                                            joueur,
                                            "",
                                            index
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                </div>
            </div>
        );
    }

    useEffect(() => {
        setLoading(false);
    }, [])

    if (tournoi === null) {
        return (
            <h3
                className="section"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                Tournoi invalide
            </h3>
        )
    }

    return (
        <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
            {loading ?
                <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                    <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
                </Placeholder>
                : (<>
                    <Accordion.Item eventKey={0} key="0">
                        <Accordion.Header>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                            >
                                Préparation
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Container>

                            </Container >
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey={1} key={1}>
                        <Accordion.Header>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                            >
                                Joueurs
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2} key={2}>
                        <Accordion.Header>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                            >
                                Génération
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Container>

                                <Container style={{ marginBottom: "20px" }}>
                                    <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
                                        {loading ?
                                            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                                                <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
                                            </Placeholder>
                                            : (tours.length === 0 ? (
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}>
                                                    <span style={{ fontStyle: "italic" }}>Aucun tour</span>
                                                    <Button className="btn btn-custom2" onClick={() => addTour(tournoi)}>
                                                        Ajouter un tour
                                                    </Button>
                                                </div>
                                            ) : (
                                                tours.map((tour, index) => {
                                                    const terrains = tour["terrains"];
                                                    return (
                                                        <Accordion.Item eventKey={index} key={index}>
                                                            <Accordion.Header>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                        justifyContent: "flex-start",
                                                                        alignItems: "flex-start",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1 1 auto" }}>
                                                                            <b>
                                                                                <span style={{ flex: "1 1 0" }}>Tour {index + 1}</span>
                                                                            </b>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div style={{ width: "95%", margin: "10px", marginTop: "0px" }}>
                                                                    <Container
                                                                        key={index}
                                                                        style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                            justifyContent: "flex-start",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap"
                                                                        }}
                                                                    >
                                                                        {terrains.length === 0 ? (
                                                                            <span>Aucun joueur</span>
                                                                        ) : (
                                                                            terrains.map((joueur, index) => {
                                                                                return (
                                                                                    getTerrain(
                                                                                        joueur,
                                                                                        "",
                                                                                        index
                                                                                    )
                                                                                );
                                                                            })
                                                                        )}
                                                                    </Container>
                                                                </div>
                                                                <div
                                                                    style={{ width: "100%" }}
                                                                    className="btn btn-custom5"
                                                                    onClick={() => {
                                                                        handleShowConfirmation(
                                                                            "",
                                                                            "Etes vous sur de vouloir supprimer ce tour ?",
                                                                            "danger",
                                                                            "Supprimer",
                                                                            "Annuler",
                                                                            null,
                                                                            () => deleteTour(tournoi, index)
                                                                        );
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faTimes}
                                                                        style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                                                                    />
                                                                    Supprimer
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    );
                                                })
                                            ))}
                                    </Accordion>
                                </Container>
                            </Container >
                        </Accordion.Body>
                    </Accordion.Item>

                </>

                )}
        </Accordion>



    );
}

export default PageTournoi;