import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider } from "./GlobalContext";
import { PreGlobalProvider } from "./PreGlobalContext";
import Database from "./Database.js";
import Auth from "./Auth.js";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkKomXT76fJEnwHKO6CGYaqwqxcf7fm1A",
  authDomain: "badlevier-95f17.firebaseapp.com",
  projectId: "badlevier-95f17",
  storageBucket: "badlevier-95f17.appspot.com",
  messagingSenderId: "850045432625",
  appId: "1:850045432625:web:6f91fe263e2b3bbc1af610",
  measurementId: "G-SNFBT0JPZY",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

const dbSingle = new Database(firebase);
const authSingle = new Auth(firebase);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PreGlobalProvider auth={authSingle} db={dbSingle}>
      <GlobalProvider firebase={firebase}>
        <App />
      </GlobalProvider>
    </PreGlobalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
