import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useGlobalContext } from "./GlobalContext";
import { useParams } from "react-router-dom";

import {
  onSnapshot,
  query,
  doc,
} from "firebase/firestore";

function Club() {
  const { db, getLicencie, dbAvailable, getUserPresentation } = useGlobalContext();
  const [membresBureau, setMembresBureau] = useState([]);
  const [president, setPresident] = useState(null);
  const [vicePresident, setVicePresident] = useState(null);
  const [tresorier, setTresorier] = useState(null);
  const [secretaire, setSecretaire] = useState(null);
  const { onglet } = useParams();
  const ongletSelected = onglet ? onglet : null;

  useEffect(() => {
    const q = query(doc(db.db, "clubs/badlevier"));
    const unsubscribeBureau = onSnapshot(q, async (doc) => {
      const badlevier = doc.data();
      const membres = [];
      for (const membreUid of badlevier["bureau"]["membres"]) {
        membres.push(await getLicencie(membreUid));
      }
      setMembresBureau(membres.filter(lic => lic != null));
      setPresident(await getLicencie(badlevier["bureau"]["président"]));
      setVicePresident(await getLicencie(badlevier["bureau"]["vice-président"]));
      setTresorier(await getLicencie(badlevier["bureau"]["trésorier"]));
      setSecretaire(await getLicencie(badlevier["bureau"]["secrétaire"]));

      if (ongletSelected !== null) {
        setTimeout(() => {
          const element = document.getElementById(ongletSelected);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 300)

      }
    });



    return () => {
      unsubscribeBureau();
    };
  }, [db, getLicencie, ongletSelected]);

  return (
    <Container>
      <h3 id="club" className="section">Présentation du club</h3>
      <Container style={{ textAlign: "center" }}>
        <h5>Vous voulez vous amuser et vous dépenser ?</h5>
        <h2>Rejoignez-nous !</h2>
        <div>
          Bonne humeur et joie de jouer animeront votre soirée.
          <section
            style={{
              textAlign: "left",
              backgroundColor: "lightyellow",
              padding: "10px",
            }}
          >
            <h4>Nouveau</h4> Depuis septembre 2023 les jeunes (8 ans -14 ans)
            peuvent aussi venir faire.
          </section>
          Nous sommes affiliés à la FFBAD et nous engageons deux équipes dans le
          championnat interclubs niveau départemental D3 et D2. N'hésitez pas à
          rejoindre l'équipe pour renforcer les effectifs.
        </div>
        <img
          alt="profil"
          className="mx-2"
          style={{
            maxWidth: "800px",
            width: "100%",
            boxShadow: "gray 0px 0px 12px 3px",
            margin: "10px",
          }}
          src={"/imageClub.jpg"}
          title={"photoEnsemble"}
        />
      </Container>

      {dbAvailable && <>
        <h3 id="bureau" className="section">Le bureau</h3>
        <Container>
          <Container>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {getUserPresentation(
                president,
                "Le président"
              )}
              {getUserPresentation(
                vicePresident,
                "Le vice-président"
              )}
              {getUserPresentation(
                tresorier,
                "La trésorière"
              )}
              {getUserPresentation(
                secretaire,
                "La secrétaire"
              )}
            </div>
          </Container>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {membresBureau.map((user, index) => {
              return getUserPresentation(user, user.displayName, index);
            })}
          </div>
        </Container>

      </>
      }
    </Container>
  );
}

export default Club;
