import React, { /*useState,*/ useEffect } from "react";
import { Container/*, Accordion, Placeholder, Button*/ } from "react-bootstrap";
//import { useGlobalContext } from "../GlobalContext";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTimes } from "@fortawesome/free-solid-svg-icons";

function Parametres({ style }) {
  //const { user, getLicencie, handleShowConfirmation, getMiniUserPresentation } = useGlobalContext();
  //const [loading, setLoading] = useState(true);


  useEffect(() => {

  }, [])

  return (
    <div id="ParametresMain" style={style}>
      <Container>
        <h3
          className="section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Parametres
        </h3>
        <Container style={{ marginBottom: "20px" }}>
          <p>Parameter</p>
        </Container>
      </Container >
    </div>
  );
}

export default Parametres;
