import React, { useState, useEffect, useCallback } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faKey, faEye } from "@fortawesome/free-solid-svg-icons";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
function ManagementTrainings({ creneau, setCurrentTraining, setModalTrainingShow }) {
  const { db, getLicencie, getDateTraining } = useGlobalContext();
  const [trainings, setTrainings] = useState([]);
  const [limitNbTraining, setLimitNbTraining] = useState(5);

  const modifyTraining = async (training) => {
    setCurrentTraining(training);
    setModalTrainingShow(true);
  };

  const getEncadrants = useCallback(async (training) => {
    const retour = [];
    for (const encUid of training["encadrants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    };
    return retour;
  }, [getLicencie]);

  const getParticipants = useCallback(async (training) => {
    const retour = [];
    for (const encUid of training["participants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    }
    return retour;
  }, [getLicencie]);


  useEffect(() => {
    let retour = [];
    const date = Timestamp.now();
    const q = query(collection(db.db, "clubs/badlevier/créneaux/" + creneau.uid + "/entraînements"), where("date", ">=", date), limit(limitNbTraining));
    const unsubscribeTraining = onSnapshot(q, async (querySnapshotTraining) => {
      await Promise.all(
        querySnapshotTraining.docs.map(async (doc) => {
          const training = doc.data();
          const trainingFindIndex = retour.findIndex(ent => ent.uid === training.uid);
          if (trainingFindIndex > -1) {
            retour[trainingFindIndex] = training;
          } else {
            retour.push(training);
          }
          training["creneau"] = creneau;
          training["encadrants"] = await getEncadrants(training);
          training["participants"] = await getParticipants(training);
        }));
      retour = retour.sort((ent1, ent2) =>
        ent1.date.toDate() > ent2.date.toDate() ? 1 : -1
      )
      setTrainings([...retour]);
    });

    return () => {
      unsubscribeTraining();
    };
  }, [db, creneau, getEncadrants, getParticipants, limitNbTraining]);

  return (
    <div style={{
      backgroundColor: "rgb(190, 190, 190)"
    }}>
      <div>
        <h6
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          Prochains entraînements
        </h6>
        <Container style={{ maxHeight: "500px", overflow: "auto" }}>
          {trainings.length === 0 ? (
            <span>Aucun entraînement</span>
          ) : (
            trainings.map((training, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid gray"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >

                      <span style={{ flex: "1 1 0", fontWeight: "bold" }}>
                        {getDateTraining(training.date)}
                      </span>
                      {
                        (db.isMemberBureau || db.isAdmin) && (
                          <div
                            className="btn btn-custom1"
                            onClick={(e) => {
                              modifyTraining(training);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              disabled={!db.isAdmin && !db.isMemberBureau}
                            />
                          </div>
                        )
                      }
                    </div>
                    {db.isMemberClub &&
                      <div style={{ width: "100%" }}>
                        {training.lieu === training.creneau.lieu ? (
                          <label>
                            Lieu
                            <span>{training.lieu}</span>
                          </label>
                        ) : (
                          <label style={{ width: "100%" }}>
                            Lieu
                            <div style={{ fontSize: "0.8em", display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-end" }}>
                              <strike> <span style={{ marginBottom: "5px" }}>{training.creneau.lieu}</span></strike>
                              <span style={{ backgroundColor: "yellow", padding: "3px" }}>{training.lieu}</span>
                            </div>
                          </label>
                        )}
                        <div>
                          <label>
                            Encadrants
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                              {training.encadrants.length !== 0 ? (training.encadrants.map((encadrant, indexP) => (
                                <span key={indexP} style={{ borderBottom: encadrant.keyPass ? "1px solid gray" : "" }} >
                                  {encadrant.keyPass && (
                                    <FontAwesomeIcon
                                      style={{ marginRight: "2px", height: "0.8em" }}
                                      icon={faKey}
                                    />
                                  )}
                                  {encadrant.displayName}
                                </span>
                              ))) : (
                                <span>-</span>
                              )}
                            </div>
                          </label>
                        </div>
                        <div>
                          <label>
                            Participants
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                              {training.participants.length !== 0 ? (training.participants.map((participant, indexP) => (
                                <span key={indexP} style={{ borderBottom: participant.keyPass ? "1px solid gray" : "" }} >
                                  {participant.keyPass && (
                                    <FontAwesomeIcon
                                      style={{ marginRight: "2px", height: "0.8em" }}
                                      icon={faKey}
                                    />
                                  )}
                                  {participant.displayName}
                                </span>
                              ))) : (
                                <span>-</span>
                              )}
                            </div>
                          </label>
                        </div>
                        {training.description !== "" && (
                          <label>
                            Description
                            <span style={{ textAlign: "right" }}>{training.description}</span>
                          </label>
                        )}
                      </div>
                    }

                  </div>

                </div>
              );
            })
          )}
          <div
            style={{ width: "100%" }}
            className="btn btn-custom2"
            onClick={() => setLimitNbTraining(limitNbTraining + 8)}
          >
            <FontAwesomeIcon
              icon={faEye}
              style={{ fontSize: "1em", marginRight: "5px" }}
            />
            Voir plus
          </div>
        </Container>
      </div >

    </div >
  );
}

export default ManagementTrainings;
