import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

// Créez un contexte
const PreGlobalContext = createContext({});

// Créez un composant Provider pour envelopper votre application
function PreGlobalProvider({ children, db, auth }) {
  //const loadingGlobal = false;
  const [loadingGlobal, setLoadingGlobal] = useState(true);
  const [online, setOnline] = useState(navigator.onLine);
  const [dbAvailable, setDbAvailable] = useState(true);
  const [user, setUser] = useState(null);

  const authUser = useCallback(async (userAuth) => {
    try {
      const now = Timestamp.now();
      const user = await db.getUser(userAuth.uid);

      if (user) {
        await db.updateUser(userAuth.uid, {
          lastLoginAt: now,
          emailVerified: userAuth.emailVerified,
        });
        user.lastLoginAt = now;
        user.emailVerified = userAuth.emailVerified;
        await db.fetchUserRights(user, false);
        return user;
      }
      //on met à jour la dernière connexion pour un joueur licencié
      const licencie = await db.getLicencie(userAuth.uid);
      if (licencie) {
        await db.updateLicencie(userAuth.uid, {
          lastLoginAt: now,
        });
        licencie.lastLoginAt = now;
        await db.fetchUserRights(licencie, true);

        return licencie;
      } else {
        await db.fetchUserRights(userAuth);
        return userAuth;
      }
    } catch (error) {
      if (error.code === "unavailable") {
        //on ne fait rien
      } else {
        setDbAvailable(false);
      }
      console.error("erreur", error);
      return null;
    }
  }, [db]);


  useEffect(() => {
    let unsubscribeUser = null;
    let unsubscribeLicencie = null;
    const unsubscribe = onAuthStateChanged(auth.auth, async (userAuth) => {
      let currentUser = null;
      if (userAuth) {
        currentUser = await authUser(userAuth);
        if (userAuth.email) {
          if (userAuth.emailVerified) {
            console.log(
              'authStateChanged : user email : "' +
              userAuth.email +
              '" connecté'
            );
          } else {
            console.log(
              'authStateChanged : user "' +
              userAuth.email +
              '" non connecté car email non vérifié'
            );
          }
        } else if (userAuth.phoneNumber) {
          currentUser = await authUser(userAuth);
          console.log(
            'authStateChanged : user phoneNumber : "' +
            userAuth.phoneNumber +
            '" connecté'
          );
        }
      } else {
        console.log("authStateChanged : no user");
      }
      if (currentUser) {
        if (unsubscribeLicencie) unsubscribeLicencie();
        const q = query(collection(db.db, "clubs/badlevier/licenciés"), where("uid", "==", currentUser.uid), limit(1));
        unsubscribeLicencie = onSnapshot(q, async (querySnapshotLicencie) => {
          await Promise.all(querySnapshotLicencie.docs.map(async (doc) => {
            const current = doc.data();
            db.fetchUserRights(current, true);
            setUser(current);
          }));
        });
        if (unsubscribeUser) unsubscribeUser();
        const q1 = query(collection(db.db, "users"), where("uid", "==", currentUser.uid), limit(1));
        unsubscribeUser = onSnapshot(q1, async (querySnapshotUser) => {
          await Promise.all(querySnapshotUser.docs.map(async (doc) => {
            const current = doc.data();
            db.fetchUserRights(current, false);
            setUser(current);
          }));
        });
      }
      setUser(currentUser);
      setLoadingGlobal(false);
    });


    /*const credentialBadlevier = Cookies.get("credentialBadlevier");
    if (credentialBadlevier) {
      try {
        const cred = JSON.parse(credentialBadlevier);
        const credential = PhoneAuthProvider.credential(
          cred.verificationId,
          cred.code
        );
        signInWithCredential(auth.auth, credential)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === "auth/code-expired") {
              handleShowAlert("Code authentification expiré", "", "warning");
            } else {
              handleShowAlert(
                "Erreur pendant la connexion par téléphone",
                "",
                "warning"
              );
            }
            //Cookies.remove("credentialBadlevier");
            //console.log("cookie supprimé");
            console.error("pb signInWithCredential", errorMessage);
          });
      } catch (error) {}
    }*/

    /*const fetchData = async () => {
      await db.fetchAll().then((result) => {
        if (result) setLoadingGlobal(false);
        setDbAvailable(true);
      }).catch((error) => {
        setDbAvailable(false);
        console.error("database non disponible", error)
      });
    };
    fetchData();*/

    const handleOnlineStatus = () => {
      setOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
      unsubscribe()
    };
  }, [db, auth.auth, authUser]);

  if (loadingGlobal && navigator.onLine) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          position: "fixed",
        }}
      >
        <img
          title="Badlevier"
          alt="Badlevier"
          src="./favicon.ico"
          style={{ width: "256px", height: "256px" }}
        ></img>
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }

  return (
    <PreGlobalContext.Provider value={{ db, online, auth, user, setUser, loadingGlobal, setLoadingGlobal, dbAvailable }}>
      {children}
    </PreGlobalContext.Provider>
  );
}

// Créez un hook pour accéder au contexte
function usePreGlobalContext() {
  const context = useContext(PreGlobalContext);
  if (context === undefined) {
    throw new Error(
      "usePreGlobalContext doit être utilisé dans un PreGlobalProvider"
    );
  }
  return context;
}

export { PreGlobalProvider, usePreGlobalContext };
