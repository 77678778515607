import React from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { useGlobalContext } from "./GlobalContext";

function Inscription() {
  const { PageUrl } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <Container>
      <h3 className="section">Tarifs saison 2023 / 2024</h3>
      <Container>
        <table style={{ width: "300px", margin: "20px auto" }}>
          <thead>
            <tr>
              <th>Type de licence</th>
              <th>Tarifs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Adulte</td>
              <td>
                <b>95€</b>
              </td>
            </tr>
            <tr>
              <td>Adulte renouvellement</td>
              <td>
                <b>90€</b>
              </td>
            </tr>
            <tr>
              <td>Jeune de 8 à 14 ans</td>
              <td>
                <b>80€</b>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>

      <h3 className="section">Inscription</h3>
      <Container
        className="card-custom1"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Container style={{ display: "flex", flexDirection: "column" }}>
          <h6>
            Etape 1 : <b>Remplissez le formulaire FFBAD</b>{" "}
          </h6>
          <Button
            className="btn btn-custom2"
            href="https://BADLEVIER25.ffbad.club/inscription"
          >
            {" "}
            Formulaire FFBAD{" "}
          </Button>
          <div className="helpItem">
            <i className="bi bi-question-circle"></i>
            <a href="https://www.ffbad.org/espaces-dedies/licencies/comment-se-licencier/">
              Aide : Comment se licencier
            </a>
          </div>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <h6>
            Etape 2 : <b>Réglez votre licence </b>
          </h6>
          <ul>
            <li>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <mark>Par carte bancaire avec Yapla</mark>{" "}
                <Button
                  className="btn btn-custom2"
                  href="https://badlevier.s2.yapla.com/fr/event-63378"
                >
                  Payer par carte bancaire
                </Button>
              </div>
            </li>
            <li>
              <p>
                <mark>Par virement bancaire</mark>{" "}
              </p>
              <div style={{ margin: "auto", marginBottom: "20px" }}>
                <table>
                  <tbody>
                    <tr>
                      <th>Code banque</th>
                      <td>12506</td>
                    </tr>
                    <tr>
                      <th>Code guichet</th>
                      <td>20014</td>
                    </tr>
                    <tr>
                      <th>Numero de compte</th>
                      <td>56023497067</td>
                    </tr>
                    <tr>
                      <th>Clé RIB</th>
                      <td>17</td>
                    </tr>
                    <tr>
                      <th>IBAN</th>
                      <td>FR76 1250 6200 1456 0234 9706 717</td>
                    </tr>
                    <tr>
                      <th>Code BIC</th>
                      <td>AGRIFRPP825</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <p>
                <mark>Par chèque</mark> <i>à l'ordre de Badlevier.</i>
              </p>
            </li>
            <li>
              <p>
                <mark>En espèce</mark>{" "}
                <i>dans une enveloppe fermée avec votre nom prénom et motif.</i>
              </p>
            </li>
          </ul>
          <center>
            A remettre à l'un des{" "}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                navigate(PageUrl.CLUB + "/bureau", { to: PageUrl.CLUB });
              }}
            >membres du bureau</a>
          </center>
        </Container>
      </Container>
    </Container>
  );
}

export default Inscription;
