import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";

const NumberSpinner = ({ afterChangeValue, minValue, maxValue, firstValue }) => {
    const [value, setValue] = useState(firstValue);
    const [loading, setLoading] = useState(false);

    const handleIncrement = async () => {
        setLoading(true);
        setValue(value + 1);
        await afterChangeValue(value + 1);
        setLoading(false);
    };

    const handleDecrement = async () => {
        setLoading(true);
        setValue(value - 1);
        await afterChangeValue(value - 1);
        setLoading(false);
    };

    useEffect(() => {
        setValue(firstValue);
    }, [firstValue])

    return (
        <div style={{
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            width: "40px",
            height: "100px",
            backgroundColor: "#1c691c",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            {value > 0 && (
                value === 1 ? <FontAwesomeIcon
                    onClick={(e) => { e.preventDefault(); handleDecrement() }}
                    disabled={loading || value <= minValue}
                    icon={faTrash}
                    style={{ color: "white", width: "28px", height: "15px", fontSize: "0.8em", padding: "5px", margin: "5px 0px 0px 0px", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", border: "none" }}
                /> :
                    <button
                        style={{ textAlign: "center", height: "30px", fontWeight: "bold", color: "green", width: "40px", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", border: "1px solid gray" }}
                        onClick={(e) => { e.preventDefault(); handleDecrement() }}
                    >
                        -
                    </button>
            )
            }

            {loading ?
                <div style={{
                    display: "flex", justifyContent: "center", alignItems: "center", flex: "1 1 auto", width: "40px", textAlign: "center"
                }}>
                    <Spinner
                        style={{
                            color: "white", fontWeight: "bold", backgroundColor: "transparent", width: "20px", height: "20px", textAlign: "center"
                        }}
                    ></Spinner>
                </div>
                :
                <input style={{
                    border: "none", color: "white", fontWeight: "bold", backgroundColor: "transparent", flex: "1 1 auto", width: "40px", textAlign: "center"
                }}
                    type="number" value={value} readOnly />
            }

            <button
                disabled={loading || value >= maxValue}
                style={{ textAlign: "center", height: "30px", fontWeight: "bold", color: "green", width: "40px", borderBottomRightRadius: "5px", borderBottomLeftRadius: "5px", border: "1px solid gray" }}
                onClick={(e) => { e.preventDefault(); handleIncrement() }}
            >
                +
            </button>

        </div >
    );
};

export default NumberSpinner;