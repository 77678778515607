import React from "react";

function MiniUserPresentation2(props) {
  const user = props.user;

  return (
    <div>
      {user ? (
        <span style={{
          marginLeft: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}>
          {user.displayName}
        </span>
      ) : (
        <span>-</span>
      )}
    </div>
  );
}

export default MiniUserPresentation2;
