import React, { useState, useEffect } from "react";
import { Container, Accordion, Placeholder, Button } from "react-bootstrap";
import { useGlobalContext } from "../GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  onSnapshot,
  query,
  /*doc,*/
  collection
} from "firebase/firestore";
import ModalJoueur from "./ModalJoueur.js";

function Joueurs({ style }) {
  const { db, /*user, getLicencie,*/ handleShowConfirmation, getMiniUserPresentation } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [joueurs/*, setJoueurs*/] = useState([]);
  const [dynamicLicencies, setDynamicLicencies] = useState([]);
  const [modalJoueurShow, setModalJoueurShow] = useState(false);
  const [currentJoueurEdit, setCurrentJoueurEdit] = useState(null);

  const onValid = (newLicencie) => {
    setModalJoueurShow(false);
  };

  const onCancel = () => {
    setModalJoueurShow(false);
  };

  const ajoutJoueur = () => {
    setCurrentJoueurEdit(null);
    setModalJoueurShow(true);
  }

  const deleteJoueur = () => {
  }

  useEffect(() => {
    if (db.isMemberClub) {
      const q = query(collection(db.db, "clubs/badlevier/licenciés"));
      const unsubscribeLicencies = onSnapshot(q, async (querySnapshotLicencie) => {
        let retour = [];
        await Promise.all(querySnapshotLicencie.docs.map(async (doc) => {
          const licencie = doc.data();
          const licencieFindIndex = retour.findIndex(cre => cre.uid === licencie.uid);
          if (licencieFindIndex > -1) {
            retour[licencieFindIndex] = licencie;
          } else {
            retour.push(licencie);
          }
        }));
        retour = retour.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        )
        setDynamicLicencies([...retour]);
        setLoading(false);
      });

      return () => {
        unsubscribeLicencies();
      };
    }

  }, [db])

  return (
    <div id="JoueursMain" style={style}>
      <Container>
        <h3
          className="section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Sélection
          <Button className="btn btn-custom2" onClick={ajoutJoueur}>Ajouter un joueur</Button>
        </h3>
        <Container style={{ marginBottom: "20px" }}>
          <Accordion defaultActiveKey="0" style={{ width: "100%" }}>
            {loading ?
              <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
              </Placeholder>
              : joueurs.length === 0 ? (
                <div style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <span style={{ fontStyle: "italic" }}>Aucun joueur</span>
                </div>
              ) : (
                joueurs.map((joueur, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        {getMiniUserPresentation(
                          joueur,
                          "",
                          index
                        )
                        }

                      </Accordion.Header>
                      <Accordion.Body>
                        <div style={{ width: "95%", margin: "10px", marginTop: "0px" }}>
                          <label>
                            Créé le
                            <span></span>
                          </label>
                          <label>
                            Créé par
                            <span></span>
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginBottom: "10px",
                              backgroundColor: "rgb(230, 230, 230)",
                              padding: "10px",
                              borderRadius: "3px"
                            }}
                          >
                            <div>
                              Joueurs
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "0.8em",
                                  marginLeft: "10px",
                                }}
                              >
                                ({joueurs.length})
                              </span>
                            </div>
                          </div>


                        </div>
                        <div
                          style={{ width: "100%" }}
                          className="btn btn-custom5"
                          onClick={() => {
                            handleShowConfirmation(
                              "",
                              "Etes vous sur de vouloir supprimer ce tournoi ?",
                              "danger",
                              "Supprimer",
                              "Annuler",
                              null,
                              () => deleteJoueur(joueur.uid)
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                          />
                          Supprimer
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
              )}
          </Accordion>
        </Container>
      </Container >

      <Container>
        {dynamicLicencies.length > 0 ? (
          <>
            <h3
              className="section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >Badlevier</h3>
            <Container style={{ display: "flex", flexDirection: "column" }}>
              {loading ?
                <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                  <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
                </Placeholder>
                : dynamicLicencies.map((joueur, index) => {
                  return (
                    getMiniUserPresentation(
                      joueur,
                      "",
                      index
                    )
                  );
                })}
            </Container>
          </>
        ) : null}
      </Container >
      <ModalJoueur
        show={modalJoueurShow}
        currentJoueurEdit={currentJoueurEdit}
        onHide={() => setModalJoueurShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </div>
  );
}

export default Joueurs;
