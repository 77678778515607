import React, { useState, useEffect } from 'react';
import { Container, Placeholder } from 'react-bootstrap';
import { useGlobalContext } from "./GlobalContext";
import {
  onSnapshot,
  query,
  collection
} from "firebase/firestore";

function Events() {

  const { db, getPhotoEvent, getDateTraining } = useGlobalContext();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/events"));
    const unsubscribeEvent = onSnapshot(q, async (querySnapshotEvent) => {
      const retour = [];
      await Promise.all(
        querySnapshotEvent.docs.map(async (doc) => {
          const eventItem = doc.data();
          const eventItemFindIndex = retour.findIndex(cre => cre.uid === eventItem.uid);
          if (eventItemFindIndex > -1) {
            retour[eventItemFindIndex] = eventItem;
          } else {
            retour.push(eventItem);
          }
        })
      );
      setEvents([...retour]);
      setLoading(false);
    });

    return () => {
      unsubscribeEvent();
    };

  }, [db]);

  return (
    <Container>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ?
          <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
            <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
          </Placeholder>
          : events.length === 0 ? (
            <span style={{ fontStyle: "italic" }}>
              Aucun évènement
            </span>
          ) : (
            events.map((eventItem, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    margin: "10px",
                    width: "100%",
                    alignItems: "center",
                    padding: "10px",
                    boxShadow: "0px 0px 10px 2px gray",
                    marginBottom: "10px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontWeight: "bold",
                      padding: "10px",
                      width: "100%",
                      height: "100%",
                      paddingBottom: "35px"
                    }}>
                    {eventItem.title !== "" &&
                      <span style={{
                        zIndex: "1",
                        backgroundColor: "white",
                        padding: "3px 5px",
                        borderRadius: "3px",
                        fontSize: "1.2em"
                      }}>
                        <b>{eventItem.title}</b>
                      </span>
                    }
                    {eventItem.dateDebut !== "" || eventItem.dateFin !== "" ?
                      (<span style={{
                        zIndex: "1",
                        marginBottom: "10px",
                        padding: "3px 5px",
                        borderRadius: "3px",
                        fontSize: "0.8em",
                        textAlign: "center"
                      }}>
                        {(eventItem.dateDebut !== "" && eventItem.dateDebut.toDate().getTime() === eventItem.dateFin.toDate().getTime()) ?
                          <div style={{ opacity: "0.8" }}>
                            {getDateTraining(eventItem.dateDebut)}
                            <br />
                            <span>{eventItem.heureDebut !== "" && "de " + eventItem.heureDebut.replace(":", "h")} {eventItem.heureFin !== "" && "à " + eventItem.heureFin.replace(":", "h")}</span>
                          </div>
                          :
                          <div style={{ opacity: "0.8" }}>
                            {eventItem.dateDebut !== "" &&
                              <span>Du {getDateTraining(eventItem.dateDebut)}</span>
                            }
                            {eventItem.heureDebut !== "" &&
                              <span>{eventItem.heureDebut !== "" && " à " + eventItem.heureDebut.replace(":", "h")}</span>
                            }
                            {(eventItem.dateDebut !== "" || eventItem.heureDebut !== "") &&
                              <br />
                            }
                            {eventItem.dateFin !== "" &&
                              <span>Au {getDateTraining(eventItem.dateFin)}</span>
                            }
                            {eventItem.heureFin !== "" &&
                              <span>{eventItem.heureFin !== "" && " à " + eventItem.heureFin.replace(":", "h")}</span>
                            }
                          </div>}
                      </span>)
                      : null}
                    {eventItem.photoUrl !== "" &&
                      <div
                        className="divPhotoEvent"
                        style={{
                          display: "flex",
                          //flex: "1 1 auto",
                          alignItems: "center",
                          justifyContent: "space-around",
                          maxHeight: "100%",
                          overflow: "auto",
                          flexDirection: "column"
                          //marginBottom: "20px"
                        }}>
                        {eventItem.photoUrl !== "" &&
                          getPhotoEvent(eventItem.photoUrl, eventItem.photoUrl ? eventItem.title : null, {
                            maxHeight: "300px",
                            maxWidth: "300px",
                            borderRadius: "3px",
                            //opacity: eventItem.title === "" ? "1" : "0.7",
                            //position: "absolute",
                            //left: "50%",
                            //top: "50%",
                            //transform: "translate(-50%, -50%)",
                          })
                        }
                      </div>
                    }
                    {eventItem.description !== "" &&
                      <span style={{
                        backgroundColor: "white",
                        padding: "3px 5px",
                        borderRadius: "3px",
                        fontSize: "0.8em",
                        flex: "1 1 auto",
                        textAlign: "center",
                        zIndex: "1"
                      }}>
                        {eventItem.description}
                      </span>
                    }

                  </div>
                </div>
              );
            })
          )}
      </Container>
    </Container >
  );
}

export default Events;
