import React from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
function HeaderLocation() {
  const location = useLocation();
  const { PageUrl } = useGlobalContext();
  return (
    <span style={{ flex: "1 1 auto", textAlign: "center" }}>
      {location.pathname === PageUrl.SIGNIN ? "Connexion" : null}
      {location.pathname === PageUrl.SIGNUP ? "Enregistrement" : null}
      {location.pathname === PageUrl.HOME ? "Badlevier" : null}
      {location.pathname === PageUrl.CONTACT ? "Contact" : null}
      {location.pathname.includes(PageUrl.CLUB) ? "Le club" : null}
      {location.pathname === PageUrl.EVENTS ? "Evènements" : null}
      {location.pathname === PageUrl.DOCUMENTS ? "Documents" : null}
      {location.pathname === PageUrl.TRAINING ? "Entraînements" : null}
      {location.pathname === PageUrl.INTERCLUB ? "Interclub" : null}
      {location.pathname === PageUrl.MANAGEMENT_CRENEAUX ? "Gestion des créneaux" : null}
      {location.pathname === PageUrl.MANAGEMENT_EQUIPES ? "Gestion des équipes" : null}
      {location.pathname === PageUrl.MANAGEMENT_EVENTS ? "Gestion des évènements" : null}
      {location.pathname === PageUrl.MANAGEMENT_DOCUMENTS ? "Gestion des documents" : null}
      {location.pathname === PageUrl.MANAGEMENT_TRESORERIE ? "Gestion de la trésorerie" : null}
      {location.pathname === PageUrl.MANAGEMENT_BOUTIQUE ? "Gestion des articles" : null}
      {location.pathname === PageUrl.SHOP ? "Boutique" : null}
      {location.pathname.includes(PageUrl.EDIT_PROFIL) ? "Mon compte" : null}
      {location.pathname === PageUrl.MANAGEMENT_LICENCES
        ? "Gestion des licences"
        : null}
      {location.pathname === PageUrl.MANAGEMENT_BUREAU
        ? "Gestion du bureau"
        : null}
      {location.pathname === PageUrl.SCHEDULE ? "Lieu et horaires" : null}
      {location.pathname === PageUrl.INSCRIPTION
        ? "Tarifs et inscriptions"
        : null}
      {location.pathname === PageUrl.GENERATOR ? "Générateur de tournoi" : null}
      {location.pathname === PageUrl.GENERATOR_JOUEURS ? "Joueurs" : null}
      {location.pathname === PageUrl.GENERATOR_PARAMETRES ? "Paramètres" : null}
      {location.pathname === PageUrl.GENERATOR_TOURNOI ? "Tournoi en cours" : null}
      {location.pathname.includes("generator/tournoi/") ? (
        <div style={{ width: "100%", display: "flex", margin: "0px 10px" }} >
          <Link
            to={PageUrl.GENERATOR}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "green",
              borderRadius: "5px",
              width: "100%",
              padding: "5px 5px",
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ marginRight: "10px" }}
            />
            Tournois
          </Link>
        </div>
      ) : null}
    </span >
  );
}

export default HeaderLocation;
