import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useGlobalContext } from "./GlobalContext";
import LoadingButton from "./LoadingButton.js";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const { handleShowAlert, PageUrl, auth, db } = useGlobalContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSignUp = async () => {
    try {
      const user = await auth.signUp(email, password);
      await db.addUser({
        uid: user.uid,
        email: user.email,
        displayName,
        firstName,
        lastName,
        validated: false,
      });
      await auth.updateProfilWithObj({
        displayName
      });
      navigate(PageUrl.HOME, { to: PageUrl.HOME });
      handleShowAlert(
        "Enregistrement réussi",
        "Un email de verification vous a été envoyé",
        "warning"
      );
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/weak-password") {
        handleShowAlert(
          "Mot de passe trop faible (6 caractères minimum)",
          errorMessage,
          "warning"
        );
      } else if (errorCode === "auth/invalid-email") {
        handleShowAlert("Email invalide", "", "warning");
      } else if (errorCode === "auth/email-already-in-use") {
        handleShowAlert("Email déjà existant", "", "warning");
      } else {
        handleShowAlert(
          "Impossible de vous enregistrer",
          errorMessage,
          "warning"
        );
      }
    }
  };

  return (
    <Form
      className="card-custom1 container"
      style={{ maxWidth: "500px", paddingTop: "20px" }}
    >
      <h3 className="mb-4">S'enregistrer</h3>
      <FloatingLabel
        label="Adresse email"
        className="mb-3"
        style={{ width: "100%" }}
      >
        <Form.Control
          type="email"
          style={{ width: "100%" }}
          autoComplete="on"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@example.com"
        />
      </FloatingLabel>
      <FloatingLabel
        label="Mot de passe"
        className="mb-3"
        style={{ width: "100%" }}
      >
        <Form.Control
          type="password"
          style={{ width: "100%" }}
          autoComplete="on"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
          placeholder="***"
        />
      </FloatingLabel>
      <FloatingLabel label="Pseudo" className="mb-3" style={{ width: "100%" }}>
        <Form.Control
          type="text"
          placeholder="john"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          required
        />
      </FloatingLabel>
      <FloatingLabel label="Prénom" className="mb-3" style={{ width: "100%" }}>
        <Form.Control
          type="text"
          placeholder="john"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </FloatingLabel>
      <FloatingLabel label="Nom" className="mb-3" style={{ width: "100%" }}>
        <Form.Control
          type="text"
          placeholder="john"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </FloatingLabel>
      <LoadingButton
        style={{ width: "100%" }}
        className="btn btn-custom2"
        onClickPromise={handleSignUp}
      >
        S'enregistrer
      </LoadingButton>
    </Form>
  );
}

export default SignUp;
