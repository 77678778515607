import React from "react";
import { useGlobalContext } from "./GlobalContext";

function UserPresentation(props) {
  const { getPhoto } = useGlobalContext();
  const user = props.user;

  return (
    <div
      className="card-custom4"
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        width: "300px",
      }}
    >
      <h2>{props.header}</h2>
      <div>
        {user ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {getPhoto(user, {
                height: "64px",
                borderRadius: "5px",
                margin: "10px",
              })}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "15px",
                }}
              >
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5>
                      {user.firstName} {user.lastName}{" "}
                    </h5>{" "}
                    surnommé {user.displayName}
                  </div>
                </div>
              </div>
            </div>
            {user.description && user.description !== "" ? (
              <div
                style={{
                  flex: "1 1 auto",
                  marginLeft: "20px",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                " {user.description} "
              </div>
            ) : null}
          </div>
        ) : (
          <h4>-</h4>
        )}
      </div>
    </div>
  );
}

export default UserPresentation;
