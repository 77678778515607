import React, { useState } from 'react';
import ModalImage from 'react-modal-image';
import { Placeholder } from "react-bootstrap";

function ModalImageCustom({ small, large, alt, style, className }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleLoading = () => {
        setIsLoading(false);
    }
    return (
        isLoading ?
            <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
            </Placeholder>
            :
            <ModalImage
                className={className}
                small={small}
                large={large}
                //hideDownload={true}
                hideZoom={true}
                alt={alt}
                style={style}
                onLoad={handleLoading} // Désactive le loader lorsque l'image est chargée
            >
            </ModalImage>
    )
}

export default ModalImageCustom;