import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import LoadingButton from "./LoadingButton";
import { useGlobalContext } from "./GlobalContext";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function SignIn() {
  const navigate = useNavigate();
  const { PageUrl, handleShowAlert, auth, db, handleSendMailVerif, isPwa } =
    useGlobalContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+33");
  const activeOtherAuth = false;

  const handleSignInWithGoogle = async () => {
    await auth
      .signInWithGoogle()
      .then((user) => {
        if (user) {
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
        } else {
          handleShowAlert(
            "Problème",
            "Connexion impossible avec Google",
            "warning"
          );
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/popup-blocked") {
          handleShowAlert(
            "Erreur pendant la connexion avec Google",
            "Veuillez autoriser l'ouverture des popups",
            "warning"
          );
        } else {
          handleShowAlert(
            "Erreur pendant la connexion avec Google",
            "Veuillez autoriser l'ouverture des popups",
            "warning"
          );
        }
        console.error("pb signInWithGoogle", errorMessage);
      });
  };

  const handleSignInWithTel = async () => {
    await auth
      .sendVerificationCode(phoneNumber)
      .then((user) => {
        if (user) {
          navigate(PageUrl.HOME, { to: PageUrl.HOME });
        } else {
          handleShowAlert(
            "Problème",
            "Erreur lors de la connexion par téléphone Google",
            "warning"
          );
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/invalid-phone-number") {
          handleShowAlert("Numéro de téléphone invalide", "", "warning");
        } else if (
          errorCode === "auth/too-many-requests" ||
          errorCode === "auth/quota-exceeded"
        ) {
          handleShowAlert("Réessayer dans un moment", "", "warning");
        } else {
          handleShowAlert(
            "Erreur pendant la connexion par téléphone",
            "",
            "warning"
          );
        }
        console.error("pb handleSignInWithTel", errorMessage);
      });
  };

  const handleSignIn = async () => {
    try {
      const user = await auth.signIn(email, password);
      if (user) {
        navigate(PageUrl.HOME, { to: PageUrl.HOME });
      } else {
        const messageMailUnVerified = (
          <p>
            Vous devez cliquer sur le lien de vérification qui vous a été envoyé
            par email
            <LoadingButton
              className="btn btn-custom1"
              onClickPromise={async () => {
                await handleSendMailVerif(auth.auth.currentUser);
              }}
            >
              Renvoyer l'email
            </LoadingButton>
          </p>
        );
        handleShowAlert("Vérification email", messageMailUnVerified, "warning");
        navigate(PageUrl.HOME, { to: PageUrl.HOME });
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/invalid-login-credentials") {
        handleShowAlert(
          "Connexion impossible",
          "Utilisateur ou mot de passe incorrect",
          "warning"
        );
      } else if (errorCode === "auth/invalid-email") {
        handleShowAlert("Connexion impossible", "Email invalide", "warning");
      } else if (errorCode === "auth/missing-password") {
        handleShowAlert(
          "Connexion impossible",
          "Mot de passe manquant",
          "warning"
        );
      } else {
        handleShowAlert(
          "Impossible de vous enregistrer",
          errorMessage,
          "warning"
        );
      }
    }
  };

  const handleForgetPassword = async () => {
    try {
      await auth.sendPasswordResetEmailUser(email);
      handleShowAlert(
        "Réinitialisation du mot de passe",
        "Email envoyé",
        "warning"
      );
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/invalid-email") {
        handleShowAlert("Email invalide", "", "warning");
      } else {
        handleShowAlert("Erreur", errorMessage, "danger");
      }
    }
  };

  if (db.currentUser != null) return <Navigate to={PageUrl.HOME} />;

  return (
    <Container style={{ maxWidth: "500px", paddingTop: "20px" }}>
      <Form className="card-custom1">
        <h3 className="mb-3">Connexion</h3>
        <FloatingLabel
          label="Adresse email"
          className="mb-3"
          style={{ width: "100%" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        >
          <Form.Control
            style={{ width: "100%" }}
            autoComplete="on"
            type="email"
            placeholder="name@example.com"
          />
        </FloatingLabel>
        <FloatingLabel
          label="Mot de passe"
          className="mb-3"
          style={{ width: "100%" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        >
          <Form.Control
            style={{ width: "100%" }}
            autoComplete="on"
            type="password"
            placeholder="***"
          />
        </FloatingLabel>
        <LoadingButton
          style={{ width: "100%" }}
          type="submit"
          className="btn btn-custom2"
          contentLoading="Connexion ..."
          onClickPromise={handleSignIn}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              float: "right",
              fontSize: "1em",
              marginRight: "5px",
              color: "white",
            }}
          />
          Se connecter par mail
        </LoadingButton>
        <p className="forgot-password text-right mt-2">
          <Link to="./" onClick={handleForgetPassword}>
            Mot de passe oublié ?
          </Link>
        </p>

        <hr className="hr" />
        <span className="mb-1">Pas encore inscrit ?</span>
        <LoadingButton
          className="btn btn-custom2"
          style={{ width: "100%" }}
          onClick={() => {
            navigate(PageUrl.SIGNUP, { to: PageUrl.SIGNUP });
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              float: "right",
              fontSize: "1em",
              marginRight: "5px",
              color: "white",
            }}
          />
          S'enregistrer par mail
        </LoadingButton>
        {activeOtherAuth && !isPwa ? (
          <>
            <hr />
            <LoadingButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-custom3"
              onClickPromise={handleSignInWithGoogle}
              contentLoading="Connexion avec Google ..."
            >
              <img
                src="google.jpg"
                alt="signWithGoogle"
                style={{ height: "32px", marginRight: "10px" }}
              />
              Se connecter avec Google
            </LoadingButton>
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <PhoneInput
                style={{ width: "100%", marginBottom: "10px" }}
                placeholder="Entrez votre numéro de téléphone"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />

              <LoadingButton
                style={{
                  width: "100%",
                }}
                className="btn btn-custom2"
                onClickPromise={handleSignInWithTel}
                contentLoading="Envoi d'un code vérification ..."
                disabled={phoneNumber === ""}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    float: "right",
                    fontSize: "1em",
                    marginRight: "5px",
                    color: "white",
                  }}
                />
                Se connecter <br />
                avec un numéro de téléphone
              </LoadingButton>
            </div>{" "}
          </>
        ) : null}
      </Form>
    </Container>
  );
}

export default SignIn;
