import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import LoadingButton from "./LoadingButton.js";
import { useGlobalContext } from "./GlobalContext.js";
import {
  onSnapshot,
  query,
  doc,
  collection,
  where, limit
} from "firebase/firestore";
import NumberSpinner from "./NumberSpinner.js";
import ModeReglement from "./ModeReglement.js";
function ModalMouvement({ show, typeMouvement, onHide, onValid, onCancel, currentUser }) {
  const {
    db,
    user,
    getLicencie,
    getLicencies,
    TypeReglement,
    StatusMouvement,
    TypeMouvement,
    handleShowConfirmation,
    handleCloseConfirmation,
    onShowModalSelection,
    getPhotoArticle2
  } = useGlobalContext();
  const [licencie, setLicencie] = useState(null);
  const [typeReglement, setTypeReglement] = useState(TypeReglement.VIREMENT_BANCAIRE);
  const [montant, setMontant] = useState("");
  const [montantDecimal, setMontantDecimal] = useState("");
  const [remiseMembreBureau, setRemiseMembreBureau] = useState(null);
  const [membresBureau, setMembresBureau] = useState([]);
  const [panier, setPanier] = useState([]);
  const [articles, setArticles] = useState([]);
  const [porteFeuille, setPorteFeuille] = useState(null);
  const [montantFinal, setMontantFinal] = useState(0);

  const mouvement = {
    typeMouvement,
    typeReglement,
    montant: montantFinal,
  }

  const afterChangeValue = async (article, nb) => {
    const ind = panier.findIndex(a => a.uid === article.uid);
    if (ind !== -1) {
      if (nb === 0) {
        panier.splice(ind, 1);
      } else {
        panier[ind]["nb"] = nb;
      }
      setPanier([...panier]);
      const m = panier.reduce((accumulateur, t) => accumulateur + t["prix"] * t["nb"], 0);
      mouvement.montant = m;
      setMontantFinal(m);
    } else {
      const newPanier = panier;
      article["nb"] = nb;
      newPanier.push(article);
      setPanier([...newPanier]);
      const m = newPanier.reduce((accumulateur, t) => accumulateur + t["prix"] * t["nb"], 0);
      mouvement.montant = m;
      setMontantFinal(m);
    }
  }

  const onValidAjout = async () => {
    let messageType;
    if (typeMouvement === TypeMouvement.DEPOT) {
      if (typeReglement === TypeReglement.PORTE_FEUILLE) {
        messageType = "Vous confirmez le paiement avec le porte-feuille ?";
      } else if (typeReglement === TypeReglement.VIREMENT_BANCAIRE) {
        messageType = "Vous confirmez avoir fait le virement ?";
      } else {
        const mBureau = await getLicencie(remiseMembreBureau);
        messageType = "Vous confirmez avoir donné l'argent à " + mBureau.displayName + " ?";
      }
    } else {
      messageType = "Vous confirmez la commande ?";
    }

    handleShowConfirmation(
      typeMouvement === TypeMouvement.DEPOT ? "Dépôt" : "Commande",
      messageType,
      "success",
      "Confirmer",
      "Annuler",
      null,
      onValidAjoutAfterConfirm
    );
  };

  const onValidAjoutAfterConfirm = async () => {
    try {
      let status;
      if (typeMouvement === TypeMouvement.DEPOT) {
        if (typeReglement === TypeReglement.VIREMENT_BANCAIRE) {
          status = StatusMouvement.ATTENTE_VALIDATION;
        } else {
          status = StatusMouvement.ATTENTE_DEPOT;
        }
      } else if (typeMouvement === TypeMouvement.ACHAT) {
        status = StatusMouvement.ATTENTE_PAIEMENT;
        /*if (typeReglement === TypeReglement.PORTE_FEUILLE) {
        status = StatusMouvement.ATTENTE_DISTRIBUTION;
      } else if (typeReglement === TypeReglement.VIREMENT_BANCAIRE) {
        status = StatusMouvement.ATTENTE_VALIDATION;
      } else {
        status = StatusMouvement.ATTENTE_DEPOT;
      }*/
      }
      const obj = {
        typeMouvement,
        motif: typeMouvement === TypeMouvement.DEPOT ? TypeMouvement.DEPOT : TypeMouvement.ACHAT,
        typeReglement,
        montant: montantFinal,
        status: status,
        remiseMembreBureau: remiseMembreBureau,
        licencie: licencie.uid,
        createur: user.uid
      };
      if (typeMouvement === TypeMouvement.ACHAT) obj["panier"] = panier;
      await db.addMouvement(obj);
      onValid();
      handleCloseConfirmation();
    } catch (error) {
      console.log("Error create mouvement", error);
    }
  };

  const selectLicencie = async (selectedLicencie) => {
    if (selectedLicencie.length !== 1)
      console.error("Plusieurs users sélectionné");
    const uid = selectedLicencie[0];
    setLicencie(await getLicencie(uid));
  };


  const remiseMembre = membresBureau.map((membre, index) => {
    return (
      <Button
        key={index}
        style={{ height: "35px" }}
        variant="secondary"
        className={remiseMembreBureau && membre.uid === remiseMembreBureau ? "buttonChecked" : ""}
        onClick={() => setRemiseMembreBureau(membre.uid)}
      >
        {membre.displayName}
      </Button>
    );
  });

  const handleMontantChange = (e) => {
    const inputValue = e.target.value;

    // Convertir la valeur en nombre
    const numericValue = parseInt(inputValue);

    // Valider la plage de valeurs
    const minValue = 0;
    const maxValue = 1000;

    if (numericValue < minValue || numericValue > maxValue) {
      // Gérer l'erreur, par exemple, afficher un message à l'utilisateur
      console.log("Montant hors de la plage autorisée");
      return;
    }

    setMontant(inputValue);
    setMontantFinal((inputValue === "" ? 0 : parseInt(inputValue)) + (montantDecimal === "" ? 0 : (parseInt(montantDecimal) / 100)));

  };

  const handleMontantDecimalChange = (e) => {
    const inputValue = e.target.value;
    // Convertir la valeur en nombre
    const numericValue = parseFloat(inputValue);
    // Valider la plage de valeurs
    const minValue = 0;
    const maxValue = 99;

    if (numericValue < minValue || numericValue > maxValue) {
      // Gérer l'erreur, par exemple, afficher un message à l'utilisateur
      console.log("Montant hors de la plage autorisée");
      return;
    }

    setMontantDecimal(inputValue);
    setMontantFinal((montant === "" ? 0 : parseInt(montant)) + (inputValue === "" ? 0 : (parseInt(inputValue) / 100)));

  };

  const manqueRemiseMembreBureau = typeReglement !== TypeReglement.VIREMENT_BANCAIRE && typeReglement !== TypeReglement.PORTE_FEUILLE && remiseMembreBureau === null;
  const manqueMontant = typeMouvement === TypeMouvement.DEPOT && montant === "" && montantDecimal === "";
  const montantIncorrect = montantFinal === 0;
  const manqueSelectionUser = licencie === null;
  //const soldePorteFeuilleInsuffisant = typeReglement === TypeReglement.PORTE_FEUILLE && porteFeuille !== null && parseFloat(montantFinal) > parseFloat(porteFeuille);

  useEffect(() => {
    if (currentUser) setLicencie(currentUser);

    if (!show) {
      setPanier([]);
      setLicencie(null);
      setMontant("");
      setRemiseMembreBureau(null);
      setTypeReglement(TypeReglement.PORTE_FEUILLE);
    }

    const q = query(doc(db.db, "clubs/badlevier"));
    const unsubscribeBureau = onSnapshot(q, async (doc) => {
      const badlevier = doc.data();
      if (badlevier) {
        const membres = [];
        for (const membreUid of badlevier["bureau"]["membres"]) {
          membres.push(await getLicencie(membreUid));
        }
        membres.push(await getLicencie(badlevier["bureau"]["président"]));
        membres.push(await getLicencie(badlevier["bureau"]["vice-président"]));
        membres.push(await getLicencie(badlevier["bureau"]["trésorier"]));
        membres.push(await getLicencie(badlevier["bureau"]["secrétaire"]));
        setMembresBureau(membres.filter(lic => lic != null));
      }
    });

    const retour = [];
    const q2 = query(collection(db.db, "clubs/badlevier/articles"));
    const unsubscribeArticle = onSnapshot(q2, async (querySnapshotArticle) => {
      await Promise.all(
        querySnapshotArticle.docs.map(async (doc) => {
          const article = doc.data();
          const articleFindIndex = retour.findIndex(cre => cre.uid === article.uid);
          if (articleFindIndex > -1) {
            retour[articleFindIndex] = article;
          } else {
            retour.push(article);
          }
        })
      );
      setArticles([...retour]);
    });

    let unsubscribePorteFeuille = null;
    if (licencie) {
      const q3 = query(collection(db.db, "clubs/badlevier/licenciés"), where("uid", "==", licencie.uid), limit(1));
      unsubscribePorteFeuille = onSnapshot(q3, async (querySnapshot) => {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const currentUser = doc.data();
            setPorteFeuille(currentUser["porte-feuille"]);
          }));
      });
    }

    return () => {
      unsubscribeBureau();
      unsubscribeArticle();
      if (unsubscribePorteFeuille) unsubscribePorteFeuille();
    };

  }, [db, currentUser, licencie, show, TypeReglement, getLicencie])

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {typeMouvement === TypeMouvement.DEPOT ? "Créditer le portefeuille" : "Nouvel commande"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginBottom: "5px" }}
          >
            {currentUser === null &&
              <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", alignItems: "center", marginBottom: "5px" }}
              >

                {licencie ? <b><span> {licencie.firstName} {licencie.lastName}</span></b> : <span style={{ color: "darkred" }}> Aucun licencié</span>}
                <Button className="btn btn-custom1"
                  onClick={async () => {
                    const licencies = await getLicencies();
                    onShowModalSelection(
                      selectLicencie,
                      "Sélectionner un licencié",
                      [],
                      () => licencies
                    )
                  }}>
                  Sélectionner
                </Button>
              </div>
            }

            {(typeMouvement === TypeMouvement.ACHAT) && (articles.length === 0 ? (
              <span style={{ fontStyle: "italic" }}>
                Aucun article
              </span>
            ) : (
              articles.map((article, index) => {
                const ind = panier.findIndex(a => a.uid === article.uid);
                const nbArticleInPanier = ind !== -1 ? panier[ind]["nb"] : 0;
                const reste = article.qte - nbArticleInPanier;
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      alignItems: "center",
                      backgroundColor: "white",
                      boxShadow: "0px 0px 5px 0px gray",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        flex: "1 1 auto",
                        display: "flex",
                        //flexDirection: "column",
                        justifyContent: "space-around",
                        margin: "10px",
                        width: "100%",
                        alignItems: "center",
                        padding: "5px 10px",
                        backgroundColor: "white"
                      }}
                    >
                      {article.photoURLArticle &&
                        getPhotoArticle2(article.photoURLArticle, article.title, {
                          maxHeight: "50px",
                          width: "60px",
                          borderRadius: "5px",
                          objectFit: "contain"
                        })
                      }
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          width: "100%"
                        }}
                      >
                        <span>
                          <b>{article.title}</b>
                        </span>
                        <span style={{ marginRight: "10px", minWidth: "70px", textAlign: "right" }}>
                          {article.prix} €
                        </span>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                          {db.isLicencie &&
                            <NumberSpinner
                              minValue={0}
                              maxValue={article["qte"]}
                              firstValue={0}
                              afterChangeValue={(nb) => afterChangeValue(article, nb)}
                            ></NumberSpinner>
                          }

                        </div>

                      </div>

                    </div>
                    <span style={{ fontSize: "1em", width: "100%", textAlign: "right", marginBottom: "5px", marginRight: "25px", fontWeight: reste < 3 ? "bold" : "", color: reste < 3 ? "darkred" : "" }}>
                      {reste === 0 ? "Plus de stock" : "Il en reste " + reste}
                    </span>
                  </div>
                );
              })
            ))}

            {typeMouvement === TypeMouvement.DEPOT && (
              <>
                <label style={{ marginBottom: "10px" }}>Montant</label>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", fontSize: "2em" }}>
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    step="1"
                    placeholder="0"
                    style={{ borderRadius: "5px", border: "1px solid gray", padding: "10px", marginRight: "5px", marginBottom: "5px", width: "100px", height: "50px", textAlign: "center", lineHeight: "1em", fontSize: "1em", fontWeight: "400" }}
                    value={montant}
                    required
                    onChange={(e) => handleMontantChange(e)}
                  />
                  ,
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    step="1"
                    placeholder="00"
                    style={{ borderRadius: "5px", border: "1px solid gray", padding: "10px", marginLeft: "10px", marginBottom: "5px", width: "70px", height: "50px", textAlign: "center", lineHeight: "1em", fontSize: "1em", fontWeight: "400" }}
                    value={montantDecimal}
                    required
                    onChange={(e) => handleMontantDecimalChange(e)}
                  />
                </div>
                {manqueMontant && (<span style={{ color: "darkred", textAlign: "center", marginRight: "5px", marginBottom: "10px" }}>Veuillez indiquer le montant</span>)}
              </>
            )}

            {typeMouvement === TypeMouvement.ACHAT && (
              <div style={{ backgroundColor: "rgb(220, 220, 220)", padding: "5px", display: "flex", justifyContent: "center", width: "100%" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>Total</span>
                <span style={{ fontWeight: "bold" }}>{montantFinal} €</span>
              </div>
            )}
          </div>

          {typeMouvement === TypeMouvement.DEPOT &&
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
                <label style={{ marginBottom: "10px", marginLeft: "5px" }}>Mode de réglement</label>
                <ButtonGroup vertical style={{ marginBottom: "10px", flex: "1 1 auto" }}>

                  <ModeReglement
                    mouvement={mouvement}
                    porteFeuille={porteFeuille}
                    changeTypeReglement={(currentType) => {
                      mouvement.typeReglement = currentType;
                      setTypeReglement(currentType);
                    }}>

                  </ModeReglement>
                </ButtonGroup>
              </div>
              {typeReglement !== TypeReglement.VIREMENT_BANCAIRE && typeReglement !== TypeReglement.PORTE_FEUILLE && (
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                  <label style={{ marginBottom: "10px", marginLeft: "5px" }}>Remis à</label>
                  <ButtonGroup vertical style={{ marginBottom: "10px" }}>
                    {remiseMembre}
                  </ButtonGroup>
                </div>
              )}
            </div>
          }
          {manqueRemiseMembreBureau && (
            <span style={{ color: "darkred", textAlign: "right", marginRight: "5px" }}>Veuillez sélectionner la personne à qui vous avez remis l'argent</span>
          )}
          {montantIncorrect && (typeMouvement === TypeMouvement.ACHAT ? (
            <span style={{ color: "darkred", textAlign: "right", marginRight: "5px" }}>Sélectionner au moins un article</span>
          ) : (
            <span style={{ color: "darkred", textAlign: "right", marginRight: "5px" }}>Le dépôt doit être supérieur à 0 €</span>
          ))}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button className="btn btn-custom1" onClick={onCancel}>
              Annuler
            </Button>
            <LoadingButton
              className="btn btn-custom2"
              onClickPromise={onValidAjout}
              disabled={
                montantIncorrect || manqueSelectionUser //|| soldePorteFeuilleInsuffisant || manqueRemiseMembreBureau
              }
            >
              Valider
            </LoadingButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal >
  );
}

export default ModalMouvement;
