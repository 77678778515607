import React from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { useGlobalContext } from './GlobalContext'

function PraticalInformation() {
  const { PageUrl } = useGlobalContext();
  return (
    <Container>
      <h3 className="section">Entraînements</h3>
      <Container>
        <table className="tableSemiTransparent" style={{ width: "100%", margin: "20px auto" }}>
          <thead >
            <tr>
              <th>Type d'entrainement</th>
              <th>Jour</th>
              <th>Heure</th>
              <th>Lieu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2"><b>Adulte</b></td>
              <td rowSpan="1"><b> Mercredi</b> </td>
              <td rowSpan="1"><b>20h30 - 22h30</b></td>
              <td rowSpan="3"><b>Salle des sports de Levier</b></td>
            </tr>
            <tr>
              <td rowSpan="1"><b> Jeudi</b></td>
              <td rowSpan="1"><b>20h - 22h</b></td>
            </tr>
            <tr>
              <td rowSpan="2"><b>Jeune</b> <br />de 8 à 14 ans</td>
              <td rowSpan="1"><b> Jeudi</b> <br /><i>de mars à octobre</i></td>
              <td rowSpan="1"><b>18h15 - 19h30</b> </td>
            </tr>
            <tr>
              <td rowSpan="1"><b> Jeudi</b> <br /> <i>d'octobre à mars</i></td>
              <td rowSpan="1"><b>18h15 - 19h30</b> </td>
              <td rowSpan="1"><b>Salle des fauvettes</b></td>
            </tr>
          </tbody>
        </table>

      </Container>

      <h3 className="section">Lieux d'entraînement</h3>
      <Container>
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-around", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", margin: "20px auto" }}>
            <h4>Salle des sports de Levier</h4>
            <p>Rue de Champagnole, 25270 Levier</p>
            <iframe
              title="mapSalleDesSports"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5447.489652614686!2d6.10421919357911!3d46.94705409999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478d0b17724d4a89%3A0x6791c3d1b88b1291!2sCENTRE%20SPORTIF!5e0!3m2!1sfr!2sfr!4v1697438183422!5m2!1sfr!2sfr"
              width="300"
              height="300"
              style={{ borderRadius: "5px", border: "gray solid 2px" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade">

            </iframe>

          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "20px auto" }}>
            <h4>Gymnase des Fauvettes</h4>
            <p>71 Rue de Besançon, 25270 Levier</p>
            <iframe
              title="mapSalleDesFauvettes"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21789.959412372045!2d6.088715832871001!3d46.94705213005512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478d0b234e33eef1%3A0x211ad965fa54d5a0!2sCentre%20d&#39;accueil%20et%20de%20loisirs%20Les%20Fauvettes!5e0!3m2!1sfr!2sfr!4v1697438311343!5m2!1sfr!2sfr"
              width="300"
              height="300"
              style={{ borderRadius: "5px", border: "gray solid 2px" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade">

            </iframe>
          </div>
        </div>

      </Container>

      <h3 className="section">Tarifs saison 2023 / 2024</h3>
      <Container>
        <table style={{ width: "300px", margin: "20px auto" }}>
          <thead>
            <tr>
              <th>Type de licence</th>
              <th>Tarifs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Adulte</td>
              <td><b>95€</b></td>
            </tr>
            <tr>
              <td>Adulte renouvellement</td>
              <td><b>80€</b></td>
            </tr>
            <tr>
              <td>Jeune de 8 à 14 ans</td>
              <td><b>80€</b></td>
            </tr>
          </tbody>
        </table>

      </Container>

      <h3 className="section">Inscription</h3>
      <Container className="card-custom1" style={{ display: "flex", flexDirection: "column" }}>
        <Container style={{ display: "flex", flexDirection: "column" }}>
          <h6>Etape 1 : <b>Remplissez le formulaire FFBAD</b> </h6>
          <Button className="btn btn-custom2" href="https://BADLEVIER25.ffbad.club/inscription"> Formulaire FFBAD </Button>
          <div className="helpItem">
            <i className="bi bi-question-circle"></i>
            <a href="https://www.ffbad.org/espaces-dedies/licencies/comment-se-licencier/">Aide : Comment se licencier</a>
          </div>
        </Container>
        <Container style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
          <h6>Etape 2 : <b>Réglez votre licence </b></h6>
          <ul>
            <li>
              <p><mark>Par virement bancaire</mark> </p>
              <div style={{ margin: "auto", marginBottom: "20px" }}>
                <table>
                  <tbody>
                    <tr>
                      <th>Code banque</th>
                      <td>12506</td>
                    </tr>
                    <tr>
                      <th>Code guichet</th>
                      <td>20014</td>
                    </tr>
                    <tr>
                      <th>Numero de compte</th>
                      <td>56023497067</td>
                    </tr>
                    <tr>
                      <th>Clé RIB</th>
                      <td>17</td>
                    </tr>
                    <tr>
                      <th>IBAN</th>
                      <td>FR76 1250 6200 1456 0234 9706 717</td>
                    </tr>
                    <tr>
                      <th>Code BIC</th>
                      <td>AGRIFRPP825</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <p><mark>Par chèque</mark> <i>à l'ordre de Badlevier.</i></p>
            </li>
            <li>
              <p><mark>En espèce</mark> <i>dans une enveloppe fermée avec votre nom prénom et motif.</i></p>
            </li>
          </ul>
          <center>
            A remettre à l'un des <Link to={PageUrl.CONTACT} >membres du bureau</Link>
          </center>
        </Container>
      </Container>

    </Container >
  );
}

export default PraticalInformation;
