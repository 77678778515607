import {
  getAuth,
  sendPasswordResetEmail,
  deleteUser,
  signInWithPopup,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  PhoneAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  RecaptchaVerifier,
} from "firebase/auth";
//import Cookies from "js-cookie";

class Auth {
  constructor(firebase) {
    console.log("constructor Auth");
    this.googleProvider = new GoogleAuthProvider();
    this.phoneProvider = new PhoneAuthProvider();
    this.auth = getAuth();
    this.firebase = firebase;

    //this.auth.settings.appVerificationDisabledForTesting = true;
    window.recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // Handle reCAPTCHA response
        },
        defaultCountry: "FR", // Ajoutez le code du pays si nécessaire
      }
    );
  }

  sendEmailVerif = async (user) => {
    await sendEmailVerification(user)
      .then(() => true)
      .catch(() => false);
  };

  logout = async () => {
    await this.auth.signOut();
    /*const credentialBadlevier = Cookies.get("credentialBadlevier");
    if (credentialBadlevier) {
      Cookies.remove("credentialBadlevier");
      console.log("Cookie supprimé");
    }*/
  };

  signInWithGoogle = async () => {
    return await signInWithPopup(this.auth, this.googleProvider)
      .then((result) => {
        if (result) return result.user;
        else return null;
      })
      .catch((error) => {
        console.error("Pb auth signInWithGoogle", error);
      });
  };

  signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const user = userCredential.user;
    if (user.emailVerified) {
      return user;
    } else {
      return null;
    }
  };

  signUp = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const user = userCredential.user;
    await this.sendEmailVerif(user);
    return user;
  };

  updateProfilWithObj = async (obj) => {
    if (this.auth.currentUser) {
      await updateProfile(this.auth.currentUser, obj)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.error("Problème pour mettre à jour le profil dans auth");
          return false;
        });
    } else {
      return false;
    }
  };

  sendPasswordResetEmailUser = async (email) => {
    return await sendPasswordResetEmail(this.auth, email);
  };

  deleteCurrentUser = async () => {
    const user = this.auth.currentUser;
    return await deleteUser(user);
  };

  getCurrentUser = () => {
    return this.auth.currentUser;
  };

  sendVerificationCode = async (phoneNumber) => {
    return await signInWithPhoneNumber(
      this.auth,
      phoneNumber,
      window.recaptchaVerifier
    )
      .then(async (confirmationResult) => {
        const code = prompt("Entrez le code de vérification :");
        return await confirmationResult
          .confirm(code)
          .then((result) => {
            /*var credential = {
              verificationId: confirmationResult.verificationId,
              code,
            };
            Cookies.set("credentialBadlevier", JSON.stringify(credential), {
              expires: 30, // expires en jours
            });*/

            return result;
          })
          .catch((error) => {
            console.error("Erreur de confirmation par code", error);
            throw error;
          });
      })
      /*.then(async (result) => {
        console.log("Authentification réussie", result.user);
        return result.user;
      })*/
      .catch((error) => {
        console.error("Erreur d'authentification par téléphone", error);
        throw error;
      });
  };
}

export default Auth;
