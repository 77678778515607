import React, { useState } from "react";
import { useGlobalContext } from "./GlobalContext.js";
import { Button } from "react-bootstrap";

function ModeReglement({ mouvement, porteFeuille, changeTypeReglement }) {
  const {
    TypeMouvement,
    TypeReglement
  } = useGlobalContext();

  const [typeReglement, setTypeReglement] = useState(mouvement.typeReglement);
  let soldePorteFeuilleInsuffisant = typeReglement === TypeReglement.PORTE_FEUILLE && porteFeuille !== null && parseFloat(mouvement.montant) > parseFloat(porteFeuille);

  let typeReglementType = [
    TypeReglement.VIREMENT_BANCAIRE,
    TypeReglement.ESPECE,
    TypeReglement.CHEQUE,
  ];
  if (mouvement.typeMouvement === TypeMouvement.ACHAT) typeReglementType.splice(0, 0, TypeReglement.PORTE_FEUILLE);
  return (
    <>
      {mouvement.typeMouvement === TypeMouvement.ACHAT && (typeReglement === TypeReglement.PORTE_FEUILLE && (soldePorteFeuilleInsuffisant ?
        <span style={{ width: "100%", color: "darkred", textAlign: "center", marginBottom: "5px" }}>Solde portefeuille insuffisant : {porteFeuille} € <br />(il manque {mouvement.montant - porteFeuille} €)</span>
        : <span style={{ width: "100%", fontWeight: "bold", color: "green", textAlign: "center", marginBottom: "5px" }}>Solde | Actuel : {porteFeuille} € / Futur : {(porteFeuille - mouvement.montant).toFixed(2)} €</span>
      ))}
      {typeReglementType.map((currentType, index) => {
        return (

          <Button
            key={index}
            //style={{ height: "40px" }}
            variant="secondary"
            className={currentType === typeReglement ? "buttonChecked" : ""}
            onClick={() => {
              setTypeReglement(currentType);
              changeTypeReglement(currentType);
            }}
          >
            {currentType}
            {currentType === TypeReglement.VIREMENT_BANCAIRE && currentType === typeReglement &&
              <table style={{
                color: "black",
                fontSize: "0.9em"
              }}>
                <tbody>
                  <tr>
                    <th>Code banque</th>
                    <td>12506</td>
                  </tr>
                  <tr>
                    <th>Code guichet</th>
                    <td>20014</td>
                  </tr>
                  <tr>
                    <th>Numero de compte</th>
                    <td>56023497067</td>
                  </tr>
                  <tr>
                    <th>Clé RIB</th>
                    <td>17</td>
                  </tr>
                  <tr>
                    <th>IBAN</th>
                    <td>FR76 1250 6200 1456 0234 9706 717</td>
                  </tr>
                  <tr>
                    <th>Code BIC</th>
                    <td>AGRIFRPP825</td>
                  </tr>
                </tbody>
              </table>
            }
          </Button >
        );
      })}
    </>
  );
}

export default ModeReglement;
