import React/*, { useState }*/ from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useGlobalContext } from "../GlobalContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faList, faUsers, faCog } from "@fortawesome/free-solid-svg-icons";
function OffCanvasLeftGenerator({ children, show, handleShow, handleClose, handleCloseRight }) {
  const { PageUrl, isPwa } = useGlobalContext();

  return (
    <div style={{ display: "flex", alignItems: isPwa ? "flex-start" : "center", height: "100%" }}>
      <div style={{ display: "flex", alignItems: isPwa ? "flex-start" : "center", height: "100%" }}
        onClick={() => {
          handleCloseRight();
          if (show) {
            handleClose();
          } else {
            handleShow();
          }
        }}>
        <div
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
          className={isPwa ? "buttonPwa" : "btn btn-custom1"}
        >
          <FontAwesomeIcon icon={faBars} style={{ height: "31px", width: "38px", margin: "0px", padding: "5px" }} />
          {isPwa ? <span style={{ fontSize: "0.8em" }}>Menu</span> : null}
        </div>
        {children}
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        name="start"
      >
        <Offcanvas.Header closeButton >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Offcanvas.Title>Générateur de tournoi</Offcanvas.Title>
          </div>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <ul style={{ flex: "1 1 auto" }}>
              <li>
                <Link to={PageUrl.HOME} onClick={handleClose}>
                  <i className="bi bi-house"></i>
                  Badlevier
                </Link>
              </li>
              <hr />
              <li>
                <Link to={PageUrl.GENERATOR} onClick={() => { handleClose(); }}>
                  <FontAwesomeIcon icon={faList} />
                  Tournois
                </Link>
              </li>
              <li>
                <Link to={PageUrl.GENERATOR_JOUEURS} onClick={() => { handleClose(); }}>
                  <FontAwesomeIcon icon={faUsers} />
                  Joueurs (par défaut)
                </Link>
              </li>
              <li>
                <Link to={PageUrl.GENERATOR_PARAMETRES} onClick={() => { handleClose(); }}>
                  <FontAwesomeIcon icon={faCog} />
                  Paramètres (par défaut)
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div >
  );
}

export default OffCanvasLeftGenerator;
