import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoadingButton({ children, className, onClickPromise, onClick, contentLoading, style, type, disabled, additionalParam }) {
    const [isLoading, setLoading] = useState(false);
    return (
        <div
            disabled={isLoading || disabled}
            onClick={(e) => {
                if (isLoading || disabled) return;
                if (onClickPromise) {
                    setLoading(true);
                    if (additionalParam) {
                        onClickPromise(...additionalParam).then(() => {
                            setLoading(false);
                        }).catch(() => {
                            setLoading(false);
                        })
                    } else {
                        onClickPromise().then(() => {
                            setLoading(false);
                        }).catch(() => {
                            setLoading(false);
                        })
                    }
                }

                if (onClick) onClick(e);
            }
            }
            className={className}
            style={style}
            type={type}
        >
            {isLoading ?
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "10px" }}
                    />
                    <span>{contentLoading}</span>
                </>
                :
                children}
        </div>
    );
}

export default LoadingButton;