import React from "react";
import { Offcanvas, Button, Container } from "react-bootstrap";
import LoadingButton from "./LoadingButton";

function OffCanvasBottom({
  button1,
  button2,
  title,
  message,
  state,
  show,
  onClose,
  onValid,
  onValidPromise,
}) {
  const className =
    state === "success"
      ? "btn-custom2"
      : state === "danger"
      ? "btn-custom5"
      : "btn-custom2";

  return (
    <Offcanvas show={show} onHide={onClose} placement="bottom" name="bottom">
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "500px",
        }}
      >
        {title !== "" ? (
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </Offcanvas.Header>
        ) : null}

        <Offcanvas.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <span style={{ color: "gray", marginBottom: "10px" }}>
              {message}
            </span>
            <LoadingButton
              className={"btn " + className}
              style={{ width: "100%" }}
              onClick={onValid ? () => onValid() : null}
              onClickPromise={onValidPromise ? () => onValidPromise() : null}
            >
              {button1}
            </LoadingButton>
            <Button
              className="btn btn-custom1"
              style={{ width: "100%" }}
              onClick={onClose}
            >
              {button2}
            </Button>
          </div>
        </Offcanvas.Body>
      </Container>
    </Offcanvas>
  );
}

export default OffCanvasBottom;
