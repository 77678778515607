import React, { useState, useEffect } from 'react';
import { Container, Placeholder, Button, Accordion } from 'react-bootstrap';
import { useGlobalContext } from "./GlobalContext";
import {
  onSnapshot,
  query,
  collection
} from "firebase/firestore";

function Documents() {

  const { db, AccessType } = useGlobalContext();
  const [documentsBureau, setDocumentsBureau] = useState([]);
  const [documentsClub, setDocumentsClub] = useState([]);
  const [documentsPublic, setDocumentsPublic] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/documents"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const docBureau = [];
      const docClub = [];
      const docPublic = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const docu = doc.data();
          if (docu.urlDocument === null) return;
          if (docu.accessType === AccessType.BUREAU) {
            docBureau.push(docu);
          } else if (docu.accessType === AccessType.CLUB) {
            docClub.push(docu);
          } else if (docu.accessType === AccessType.PUBLIC) {
            docPublic.push(docu);
          }
        })
      );
      setDocumentsBureau(docBureau);
      setDocumentsClub(docClub);
      setDocumentsPublic(docPublic);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [db, AccessType]);

  let struc = [];
  if (db.isMemberBureau) {
    struc.push({
      accessType: AccessType.BUREAU,
      list: documentsBureau,
      description: "Réservé aux membres du bureau"
    });
  }
  if (db.isMemberClub) {
    struc.push({
      accessType: AccessType.CLUB,
      list: documentsClub,
      description: "Réservé aux membres du club"
    });
  }
  struc.push({
    accessType: AccessType.PUBLIC,
    list: documentsPublic,
    description: "Tout le monde peut voir"
  });

  return (
    <Container>
      {struc.map((strucItem, index) => {
        return (
          <div key={index}>
            {struc.length > 1 &&
              <div
                className="section"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <h4 style={{ marginBottom: "0px" }}>{strucItem.accessType}</h4>
                  <span style={{ lineHeight: "1.2em", fontStyle: "italic", fontSize: "0.8em" }}>{strucItem.description}</span>
                </div>
              </div>
            }
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {loading ?
                <Placeholder as="div" animation="glow" style={{ width: '100%', height: "60px", marginBottom: "10px" }} >
                  <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
                </Placeholder>
                : strucItem.list.length === 0 ?
                  <span style={{ fontStyle: "italic", marginTop: "10px", marginBottom: "10px" }}>Aucun document</span>
                  : (
                    <>
                      <Accordion
                        defaultActiveKey="0"
                        style={{ maxWidth: "600px", width: "100%" }}
                      >
                        {strucItem.list.map((documentItem, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                backgroundColor: "white",
                                marginBottom: "10px",
                                padding: "10px",
                                border: "1px solid gray",
                                borderRadius: "5px",
                                boxShadow: "3px 3px 15px 0px gray",
                              }}
                            >
                              <div
                                style={{
                                  flex: "1 1 auto",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    flex: "1 1 auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <span>
                                    <b>{documentItem.title}</b>
                                  </span>
                                  <span>
                                    {documentItem.description}
                                  </span>
                                </div>
                              </div>
                              {<Button target="blank" className="btn btn-custom1" href={documentItem.urlDocument}>
                                Ouvrir
                              </Button>}
                            </div>
                          );
                        })}
                      </Accordion>
                    </>
                  )}

            </Container>
          </div>
        );
      })}
    </Container >
  );
}

export default Documents;
