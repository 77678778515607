import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Button, Accordion, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import ModalDocument from "./ModalDocument.js";
import {
  onSnapshot,
  query,
  collection,
} from "firebase/firestore";
function ManagementDocuments() {
  const {
    db,
    AccessType,
  } = useGlobalContext();
  const [documentsBureau, setDocumentsBureau] = useState([]);
  const [documentsClub, setDocumentsClub] = useState([]);
  const [documentsPublic, setDocumentsPublic] = useState([]);
  const [currentDocumentEdit, setCurrentDocumentEdit] = useState(null);
  const [accessType, setAccessType] = useState(AccessType.BUREAU);
  const [modalDocumentShow, setModalDocumentShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const modifyDocument = (documentItem) => {
    setCurrentDocumentEdit(documentItem);
    setModalDocumentShow(true);
  };
  const ajoutDocument = (accessType) => {
    setCurrentDocumentEdit(null);
    setAccessType(accessType);
    setModalDocumentShow(true);
  };

  const onValid = () => {
    setModalDocumentShow(false);
  };

  const onCancel = () => {
    setModalDocumentShow(false);
  };

  useEffect(() => {
    if (db.isMemberBureau) {
      const q = query(collection(db.db, "clubs/badlevier/documents"));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const docBureau = [];
        const docClub = [];
        const docPublic = [];
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const docu = doc.data();
            if (docu.accessType === AccessType.BUREAU) {
              docBureau.push(docu);
            } else if (docu.accessType === AccessType.CLUB) {
              docClub.push(docu);
            } else if (docu.accessType === AccessType.PUBLIC) {
              docPublic.push(docu);
            }
          })
        );
        setDocumentsBureau(docBureau);
        setDocumentsClub(docClub);
        setDocumentsPublic(docPublic);
        setLoading(false);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [db, AccessType]);

  const struc = [
    {
      accessType: AccessType.BUREAU,
      list: documentsBureau
    },
    {
      accessType: AccessType.CLUB,
      list: documentsClub
    },
    {
      accessType: AccessType.PUBLIC,
      list: documentsPublic
    }
  ]

  return (
    <Container>
      {struc.map((strucItem, index) => {
        return (
          <div key={index}>
            <div
              className="section"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <h4 style={{ marginBottom: "0px" }}>{strucItem.accessType}</h4>
                <span style={{ lineHeight: "1.2em", fontStyle: "italic", fontSize: "0.8em" }}>Réservé aux membres du bureau</span>
              </div>
              <Button className="btn btn-custom2" onClick={ajoutDocument.bind(this, strucItem.accessType)}>
                Ajouter
              </Button>
            </div>
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {loading ?
                <Placeholder as="div" animation="glow" style={{ width: '100%', height: "60px", marginBottom: "10px" }} >
                  <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
                </Placeholder>
                : strucItem.list.length === 0 ?
                  <span style={{ fontStyle: "italic", marginBottom: "10px" }}>Aucun document</span>
                  : (
                    <>
                      <Accordion
                        defaultActiveKey="0"
                        style={{ maxWidth: "600px", width: "100%" }}
                      >
                        {strucItem.list.map((documentItem, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                backgroundColor: "white",
                                marginBottom: "10px",
                                padding: "10px",
                                border: "1px solid gray",
                                borderRadius: "5px",
                                boxShadow: "3px 3px 15px 0px gray",
                              }}
                            >
                              <div
                                style={{
                                  flex: "1 1 auto",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    flex: "1 1 auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <span>
                                    <b>{documentItem.title}</b>
                                  </span>
                                  <span>
                                    {documentItem.description}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="btn btn-custom1"
                                onClick={(e) => {
                                  modifyDocument(documentItem);
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPenToSquare}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </Accordion>
                    </>
                  )}

            </Container>
          </div>
        );
      })}

      <ModalDocument
        show={modalDocumentShow}
        currentDocumentEdit={currentDocumentEdit}
        accessType={accessType}
        onHide={() => setModalDocumentShow(false)}
        onValid={onValid}
        onCancel={onCancel}
      />
    </Container >
  );
}

export default ManagementDocuments;
