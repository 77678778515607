import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";
import { useLocation } from "react-router";

import Homepage from "./Homepage";
import Generator from "./Generator/Generator";
import Joueurs from "./Generator/Joueurs";
import Parametres from "./Generator/Parametres";
import Contact from "./Contact";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import EditProfil from "./EditProfil";
import Events from "./Events";
import Interclub from "./Interclub";
import Shop from "./Shop";
import PraticalInformation from "./PraticalInformation.js";
import ManagementLicences from "./ManagementLicences.js";
import Club from "./Club.js";
import Trainings from "./Trainings.js";
import ManagementBureau from "./ManagementBureau.js";
import ManagementCreneaux from "./ManagementCreneaux.js";
import Inscription from "./Inscription.js";
import Partners from "./Partners.js";
import Footer from "./Footer.js";
import ManagementEquipes from "./ManagementEquipes.js";
import ManagementTresorerie from "./ManagementTresorerie.js";
import ManagementBoutique from "./ManagementBoutique.js";
import ManagementEvents from "./ManagementEvents.js";
import Documents from "./Documents.js";
import ManagementDocuments from "./ManagementDocuments.js";
import Tournoi from "./Generator/Tournoi.js";
function Main({ style, children }) {
  const { PageUrl, isPwa, dbAvailable } = useGlobalContext();
  const location = useLocation();
  useEffect(() => {
    //resize();
  }, [location.pathname]);

  return (
    <div id="bodyMain" style={style}>
      <Routes>
        {!dbAvailable ?
          <>
            <Route exact path="/" element={<Homepage />} />
            <Route path={PageUrl.INSCRIPTION} element={<Inscription />} />
            <Route path={PageUrl.CLUB} element={<Club />} />
            <Route path={PageUrl.CONTACT} element={<Contact />} />
            <Route path={PageUrl.PARTNERS} element={<Partners />} />
            <Route path={PageUrl.SIGNIN} element={<SignIn />} />
            <Route path={PageUrl.SIGNUP} element={<SignUp />} />
            <Route path={PageUrl.GENERATOR} element={<Generator />} />
          </>
          :
          <>
            <Route exact path="/" element={<Homepage />} />
            <Route path={PageUrl.SIGNIN} element={<SignIn />} />
            <Route path={PageUrl.SIGNUP} element={<SignUp />} />
            <Route path={PageUrl.EDIT_PROFIL} element={<EditProfil />} />
            <Route path={PageUrl.EDIT_PROFIL + "/:onglet"} element={<EditProfil />} />
            <Route path={PageUrl.TRAINING} element={<Trainings />} />
            <Route path={PageUrl.INSCRIPTION} element={<Inscription />} />
            <Route path={PageUrl.CONTACT} element={<Contact />} />
            <Route path={PageUrl.PARTNERS} element={<Partners />} />
            <Route
              path={PageUrl.PRATICAL_INFORMATION}
              element={<PraticalInformation />}
            />
            <Route path={PageUrl.CLUB} element={<Club />} />
            <Route path={PageUrl.CLUB + "/:onglet"} element={<Club />} />
            <Route path={PageUrl.EVENTS} element={<Events />} />
            <Route path={PageUrl.DOCUMENTS} element={<Documents />} />
            <Route path={PageUrl.INTERCLUB} element={<Interclub />} />
            <Route path={PageUrl.SHOP} element={<Shop />} />
            <Route
              path={PageUrl.MANAGEMENT_LICENCES}
              element={<ManagementLicences />}
            />
            <Route
              path={PageUrl.MANAGEMENT_BUREAU}
              element={<ManagementBureau />}
            />
            <Route
              path={PageUrl.MANAGEMENT_CRENEAUX}
              element={<ManagementCreneaux />}
            />
            <Route
              path={PageUrl.MANAGEMENT_EQUIPES}
              element={<ManagementEquipes />}
            />
            <Route
              path={PageUrl.MANAGEMENT_EVENTS}
              element={<ManagementEvents />}
            />
            <Route
              path={PageUrl.MANAGEMENT_DOCUMENTS}
              element={<ManagementDocuments />}
            />
            <Route
              path={PageUrl.MANAGEMENT_TRESORERIE}
              element={<ManagementTresorerie />}
            />
            <Route
              path={PageUrl.MANAGEMENT_BOUTIQUE}
              element={<ManagementBoutique />}
            />

            <Route path={PageUrl.GENERATOR_TOURNOI + "/:tournoiUid"} element={<Tournoi />} />
            <Route path={PageUrl.GENERATOR_JOUEURS} element={<Joueurs />} />
            <Route path={PageUrl.GENERATOR_PARAMETRES} element={<Parametres />} />
            <Route path={PageUrl.GENERATOR} element={<Generator />} />
          </>
        }

      </Routes>
      {children}
      {isPwa ? null : <Footer />}
    </div>
  );
}

export default Main;
