import React from "react";
import { useGlobalContext } from "./GlobalContext";

function MiniUserPresentation(props) {
  const { getPhoto } = useGlobalContext();
  const user = props.user;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        borderRadius: "5px",
        padding: "5px",
        margin: "5px",
        fontSize: "0.8em",
        backgroundColor: "white"
      }}
    >
      <div>
        {user ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {getPhoto(user, {
                height: "20px",
                borderRadius: "5px",
              })}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>
                      {user.firstName} {user.lastName}{" "}
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h4>-</h4>
        )}
      </div>
    </div>
  );
}

export default MiniUserPresentation;
