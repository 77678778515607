import React from "react";
import { Offcanvas, Badge } from "react-bootstrap";
import { useGlobalContext } from "./GlobalContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHandshakeSimple,
  faShoppingCart,
  faEuroSign,
  faCalendarAlt,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
function OffCanvasLeft({
  children,
  borderOffcanvasLeft,
  setOnGenerator,
  show,
  handleShow,
  handleClose,
  handleCloseRight,
}) {
  const { dbAvailable, PageUrl, isPwa, user } = useGlobalContext();

  return (
    <div
      className="buttonMenuPwa"
      style={{
        display: "flex",
        alignItems: isPwa ? "flex-start" : "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: isPwa ? "flex-start" : "center",
          height: "100%",
        }}
        onClick={() => {
          handleCloseRight();
          if (show) {
            handleClose();
          } else {
            handleShow();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: borderOffcanvasLeft,
          }}
          className={isPwa ? "buttonPwa" : "btn btn-custom1"}
        >
          <FontAwesomeIcon
            icon={faBars}
            style={{ height: "22px", margin: "0px", padding: "5px" }}
          />
          {isPwa ? <span style={{ fontSize: "0.8em" }}>Menu</span> : null}
        </div>
        {children}
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        name="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Club de badminton Badlevier</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <ul style={{ flex: "1 1 auto" }}>
              <li>
                <Link to={PageUrl.HOME} onClick={handleClose}>
                  <i className="bi bi-house"></i>
                  Accueil
                </Link>
              </li>
              <hr />
              <li>
                <Link
                  to={
                    user && user.email === "jonathan.merandat@outlook.com"
                      ? PageUrl.GENERATOR
                      : null
                  }
                  onClick={handleClose}
                >
                  <i className="bi bi-dice-3"></i>
                  <span>Générateur de tournoi</span>
                  <Badge
                    bg="warning"
                    style={{
                      top: "-7px",
                      right: "5px",
                      fontSize: "0.5em",
                      position: "absolute",
                      color: "black",
                    }}
                  >
                    Bientôt disponible
                  </Badge>
                </Link>
              </li>
              <hr />
              <li>
                <Link to={PageUrl.CLUB} onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="rgb(28, 105, 28)"
                      d="M615.5 59.6C560.6-17.9 433.3-19.2 332.7 52c-57.2 40.5-94.4 96-106.5 150.6-10.7 48.2-34.6 91.7-66.1 129.2-17.9-10-32.2.2-33.9 1.4L13.6 412c-14.4 10.1-18 30-7.8 44.5l29.4 41.9c9.9 14.1 29.7 18.2 44.5 7.8l112.6-78.8c9.5-6.7 13.7-17.6 13-28.4 36-13.7 73.8-21.7 112.3-21.7 31.6 0 112.3 21.8 211.5-48.4 101.8-72.2 140.6-192.8 86.4-269.3zM61.3 480l-29.4-41.8 112.6-78.8 29.4 41.9L61.3 480zm130-109.6l-9.7-13.9c18.7-21.7 34.8-44.9 47.4-69.6 11 33.1 29.1 49.8 44.4 61.5-27.6 3.9-55.2 11.5-82.1 22zm319.2-67.6c-85.6 60.6-194 62.4-238.3-.1-43.9-62-8.5-162.7 79-224.7 84.7-60 193.6-63.1 238.3 0 42 59.4 11.1 161.1-79 224.8z"
                    />
                  </svg>
                  Le club
                </Link>
              </li>
              <li>
                <Link to={PageUrl.INSCRIPTION} onClick={handleClose}>
                  <FontAwesomeIcon icon={faEuroSign} />
                  Tarifs et inscriptions
                </Link>
              </li>
              <li>
                <Link to={PageUrl.SHOP} onClick={handleClose}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                  Boutique
                </Link>
              </li>
              {false ? (
                <li>
                  <Link to={PageUrl.PRATICAL_INFORMATION} onClick={handleClose}>
                    <i className="bi bi-info-circle"></i>
                    Infos pratiques
                    <i className="bi bi-caret-arrow"></i>
                  </Link>
                </li>
              ) : null}
              {dbAvailable && (
                <>
                  <hr />
                  <li>
                    <Link to={PageUrl.EVENTS} onClick={handleClose}>
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        style={{ maringRight: "10px" }}
                      />
                      Evènements
                    </Link>
                  </li>
                  <li>
                    <Link to={PageUrl.TRAINING} onClick={handleClose}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="rgb(28, 105, 28)"
                          d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
                        />
                      </svg>
                      Entrainements
                    </Link>
                  </li>
                  <li>
                    <Link to={PageUrl.INTERCLUB} onClick={handleClose}>
                      <i className="bi bi-trophy"></i>
                      Interclubs
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link to={PageUrl.DOCUMENTS} onClick={handleClose}>
                      <FontAwesomeIcon
                        icon={faFolderOpen}
                        style={{ maringRight: "10px" }}
                      />
                      Documents
                    </Link>
                  </li>
                </>
              )}

              <li>
                <Link to={PageUrl.PARTNERS} onClick={handleClose}>
                  <FontAwesomeIcon icon={faHandshakeSimple} />
                  Partenaires
                </Link>
              </li>
              <li>
                <Link to={PageUrl.CONTACT} onClick={handleClose}>
                  <i className="bi bi-envelope"></i>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OffCanvasLeft;
